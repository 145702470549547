import { AppDispatch, store } from '../index';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../config/axios';
import {
    CLIENTS_LIGHT_LIST, CLIENTS_LIST,
    CLIENTS_LOG_LIST,
    CLIENT_CREATE,
    CLIENT_EDIT,
    CLIENT_REMOVE } from '../../constants/api-endpoints';
import { setClients, setLoadingClients, updateClientAtTable } from './client-list-reducer';
import { closeModal } from '../modal/reducer';
import { setClient, setClientLogging, setClientLoggingLoading, setLoadingClient } from './client-reducer';
import { IClient } from '../../interfaces/client.interface';
import { showSnackbar } from '../snackbar/reducer';
import { errorMapper } from '../../utils/errorMapper';
import { ErrorMessages } from '../../constants/errors';
import { ResponseMessage } from '../../constants/response.messages';
import { IAddClientForm } from '../../components/modals/AddClient/AddClientForm';
import {setClientLight, setLoadingClientLight } from './client-light-reducer';

export const getClients = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse =
      await axiosInstance.get(CLIENTS_LIST, {params});
        dispatch(setClients(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingClients(false));
    }
};

export const getClient = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse =
          await axiosInstance.get(CLIENTS_LIST, {params});
        dispatch(setClient(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingClient(false));
    }
};

export const getClientsLight = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        dispatch(setLoadingClientLight(true));
        const {data}: AxiosResponse =
            await axiosInstance.get(CLIENTS_LIGHT_LIST, {params});
        dispatch(setClientLight(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingClientLight(false));
    }
};

export const createClient = (formData: IAddClientForm) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination}}} = store.getState();
    try{
        const {data}: AxiosResponse<any> =
          await axiosInstance.post(CLIENT_CREATE, formData);
        dispatch(setClient({...data, userTotal: {
            totalInput: 0,
            totalOutput: 0
        }}));
        dispatch(getClients({
            pageSize:pagination.perPage,page: pagination.page+1
        },));
        dispatch(closeModal());
        dispatch(showSnackbar(ResponseMessage.SAVED_SUCCESS, 'success'));
    }catch (e: any) {
        dispatch(showSnackbar(errorMapper(e.response.data.message,e), 'error'));
    } finally {
        dispatch(setLoadingClient(false));
    }
};

export const removeClient = (id: number) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination}}, clientList: {clients}} = store.getState();
    try{
        await axiosInstance.delete(`${CLIENT_REMOVE}/${id}`);
        if((clients.length-1)===0){
            dispatch(getClients({
                pageSize:pagination.perPage,page: pagination.page
            },));
        } else {
            dispatch(getClients({
                pageSize:pagination.perPage,page: pagination.page+1
            },));
        }

        dispatch(closeModal());
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.REMOVE_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingClient(false));
    }
};

export const editClient = (
    formData: IClient,
    updateList?: boolean,
    params?: any) => async (dispatch: AppDispatch)=>{
    const {id, ...body} = formData;
    try{
        dispatch(setLoadingClient(true));
        const {data}: AxiosResponse =
          await axiosInstance.patch(CLIENT_EDIT+id, body);
        dispatch(setClient(data));
        dispatch(updateClientAtTable(data));
        dispatch(closeModal());
        if(updateList){
            dispatch(getClients(params));
        }
        dispatch(getClientLogs({id, page: 1, pageSize: 5}));
        dispatch(showSnackbar(ResponseMessage.UPDATED_SUCCESS, 'success'));
    }catch (e: any) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.UPDATE_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingClient(false));
    }
};

export const getClientLogs = (params?: any) => async (dispatch: AppDispatch)=>{
    try{
        const {id,...query} = params;
        const {data}: AxiosResponse =
          await axiosInstance.get(CLIENTS_LOG_LIST+id,{params:query});
        dispatch(setClientLogging(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.CLIENT_LOGS_GET_ERROR,e), 'error'));
    } finally {
        dispatch(setClientLoggingLoading(false));
    }
};
