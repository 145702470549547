import {MenuItem, TextField} from '@mui/material';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {getPayments} from '../../store/payments/thunk';

export interface IPaymentSelect {
    register: any;
    disabled?: boolean;
    label?: string;
    errors: any;
    name?: string;
    sx?: any;
    defaultValue?: number;
    required?: boolean;
}

export const PaymentSelect: React.FC<IPaymentSelect> = ({
    sx,
    register,
    errors,
    name='payment',
    defaultValue,
    disabled=false,
    required=true,
    label
}) =>{
    const dispatch = useAppDispatch();
    const { data: payments, isLoading } = useAppSelector(state => state.payments);

    useEffect(()=>{
        if(payments.length===0 && !isLoading){
            dispatch(getPayments());
        }
    },[]);

    return (
        <TextField
            sx={sx}
            fullWidth={true}
            margin="normal"
            size="small"
            label={label ?? 'Платежка'}
            disabled={disabled}
            select
            required={required}
            error={!!errors[name]}
            defaultValue={defaultValue ? defaultValue : ''}
            helperText={errors[name] ? errors[name].message : null}
            id={name}
            {...register(name,
                {
                    required: {value: required, message: 'выберите платежку'},
                })}
        >
            {payments && payments.map((payment) => (
                <MenuItem key={'pay_'+payment.id} value={payment.id}>
                    {payment.name}
                </MenuItem>
            ))}
        </TextField>
    );
};
