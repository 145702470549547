import { AppDispatch, store } from '../index';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../config/axios';
import { TRANSFER_CREATE, TRANSFER_LIST, TRANSFER_REMOVE, TRANSFER_UPDATE } from '../../constants/api-endpoints';
import {showSnackbar} from '../snackbar/reducer';
import {errorMapper} from '../../utils/errorMapper';
import {ErrorMessages} from '../../constants/errors';
import {setLoadingTransfers, setTransfers } from './transfers-reducer';
import {closeModal} from '../modal/reducer';
import { IAddTransferForm } from '../../components/modals/AddTransfer/AddTransferForm';
import { IUpdateTransferForm } from '../../components/modals/UpdateTransfer/UpdateTransferForm';

export const getTransfers = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse =
            await axiosInstance.get(TRANSFER_LIST, {params});
        dispatch(setTransfers(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingTransfers(false));
    }
};

export const addTransfer = (newExpense:IAddTransferForm) => async (dispatch: AppDispatch)=>{
    try{
        const {modal: {data: {onConfirm}}} = store.getState();
        dispatch(setLoadingTransfers(true));
        await axiosInstance.post(TRANSFER_CREATE, newExpense);
        dispatch(closeModal());
        if(onConfirm){onConfirm();}
        dispatch(showSnackbar('запись добавлена', 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.CREATE_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingTransfers(false));
    }
};

export const updateTransfer = (id: number, updatedExpense:IUpdateTransferForm) => async (dispatch: AppDispatch)=>{
    try{
        await axiosInstance.patch(`${TRANSFER_UPDATE}${id}`, updatedExpense);
        dispatch(getTransfers());
        dispatch(closeModal());
        dispatch(showSnackbar('запись обновлена', 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.UPDATE_DATA,e), 'error'));
    }
};


export const removeTransfer = (id: number) => async (dispatch: AppDispatch)=>{
    try{
        const {modal: {data: {pagination}}} = store.getState();
        dispatch(setLoadingTransfers(true));
        await axiosInstance.delete(`${TRANSFER_REMOVE}${id}`);
        dispatch(getTransfers(pagination));
        dispatch(closeModal());
        dispatch(showSnackbar('запись удалена', 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.REMOVE_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingTransfers(false));
    }
};
