import { routersPath } from '../../router/routes-path';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreateIcon from '@mui/icons-material/Create';

export const adminItems = [
  {
    title: 'Рег.менеджеры',
    path: routersPath.ADMIN_DASHBOARD_SENIORS,
    icon: AssignmentIndIcon,
  },
  {
    title: 'Менеджеры',
    path: routersPath.ADMIN_DASHBOARD_MANAGERS,
    icon: AssignmentIndIcon,
  },
  {
    title: 'Клиенты',
    path: routersPath.ADMIN_DASHBOARD_CLIENTS,
    icon: AssignmentIndIcon,
  },
  {
    title: 'Пополнения',
    path: routersPath.ADMIN_DASHBOARD_INPUT,
    icon: FileDownloadIcon,
  },
  {
    title: 'Выводы',
    path: routersPath.ADMIN_DASHBOARD_OUTPUT,
    icon: FileUploadIcon,
  },
  {
    title: 'Расходы',
    path: routersPath.ADMIN_DASHBOARD_EXPENSES,
    icon: MonetizationOnIcon,
  },
  {
    title: 'Перевод между кошельками',
    path: routersPath.ADMIN_DASHBOARD_TRANSFERS,
    icon: ImportExportIcon,
  },
  {
    title: 'Зарплаты',
    path: routersPath.ADMIN_DASHBOARD_SALARY,
    icon: AccountBalanceWalletIcon,
  },
  {
    title: 'Карандаш',
    path: routersPath.ADMIN_DASHBOARD_PENCIL,
    icon: CreateIcon,
  },
  {
    title: 'Отчеты',
    path: routersPath.ADMIN_DASHBOARD_REPORTS,
    icon: MonetizationOnIcon,
  },
];

export const seniorItems = [
  {
    title: 'Менеджеры',
    path: routersPath.ADMIN_DASHBOARD_MANAGERS,
    icon: AssignmentIndIcon,
  },
  {
    title: 'Клиенты',
    path: routersPath.ADMIN_DASHBOARD_CLIENTS,
    icon: AssignmentIndIcon,
  },
  {
    title: 'Пополнения',
    path: routersPath.ADMIN_DASHBOARD_INPUT,
    icon: FileDownloadIcon,
  },
  {
    title: 'Выводы',
    path: routersPath.ADMIN_DASHBOARD_OUTPUT,
    icon: FileUploadIcon,
  },
  {
    title: 'Расходы',
    path: routersPath.ADMIN_DASHBOARD_EXPENSES,
    icon: MonetizationOnIcon,
  },
  {
    title: 'Карандаш',
    path: routersPath.ADMIN_DASHBOARD_PENCIL,
    icon: CreateIcon,
  },
];

export const managerItems = [
  {
    title: 'Клиенты',
    path: routersPath.MANAGER_DASHBOARD_HOME,
    icon: AssignmentIndIcon,
  },
  {
    title: 'Пополнения',
    path: routersPath.ADMIN_DASHBOARD_INPUT,
    icon: FileDownloadIcon,
  },
  {
    title: 'Выводы',
    path: routersPath.ADMIN_DASHBOARD_OUTPUT,
    icon: FileUploadIcon,
  },
  {
    title: 'Карандаш',
    path: routersPath.MANAGER_DASHBOARD_PENCIL,
    icon: CreateIcon,
  },
];
