import AddClient from './AddClient';
import AddManager from './AddManager';
import ConfirmModal from './Confirm';
import { EditClient } from './EditClient';
import { ProfileModal } from './ProfileModal/ProfileModal';
import EditManager from './EditManager';
import { TransactionOutput } from './TransactionOutput';
import { TransactionInput } from './TransactionInput';
import AddExpense from './AddExpense';
import AddTransfer from './AddTransfer';
import { PaySalaryModal } from './PaySalary';
import { TransactionOutputPage } from './TransactionOutputPage';
import { TransactionInputPage } from './TransactionInputPage';
import { UpdateInputTransaction } from './UpdateInputTransaction';
import { UpdateOutputTransaction } from './UpdateOutputTransaction';
import UpdateTransfer from './UpdateTransfer';
import { UpdateExpense } from './UpdateExpense';
import UpdateSalaryLog from './UpdateSalaryLog';
import PencilCreateModal from './PencilCreate';
import UpdatePencilLog from './UpdatePencil';

export const ModalTypes = {
    ProfileModal,
    AddManager,
    ConfirmModal,
    AddClient,
    EditClient,
    EditManager,
    TransactionOutput,
    TransactionInput,
    AddExpense,
    UpdateExpense,
    AddTransfer,
    PaySalaryModal,
    TransactionOutputPage,
    TransactionInputPage,
    UpdateInputTransaction,
    UpdateOutputTransaction,
    UpdateTransfer,
    UpdateSalaryLog,
    PencilCreateModal,
    UpdatePencilLog
};
