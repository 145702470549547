import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';

interface LoaderProps {
    center?: boolean;
}

function Loader ({center = false}: LoaderProps) {

    const centerStyle: SxProps<Theme> = {
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        position: 'fixed',
        zIndex: 2
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            minHeight: '150px',
            alignItems: 'center',
            ...(center ? centerStyle : {}) }}>
            <CircularProgress />
        </Box>
    );
}

export default Loader;
