import React from 'react';
import { ModalTypes } from './modalTypes';
import { useAppSelector } from '../../store/hooks';

const Modal: React.FC = () => {
  const modal = useAppSelector((state) => state.modal);
  const ModalComponent = modal.type ? ModalTypes[modal.type] : modal.type;

  return <>{ModalComponent && <ModalComponent {...modal.data} />}</>;
};

export default Modal;
