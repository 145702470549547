import {AppDispatch} from '../index';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../config/axios';
import {PAYMENTS_LIST} from '../../constants/api-endpoints';
import {showSnackbar} from '../snackbar/reducer';
import {errorMapper} from '../../utils/errorMapper';
import {ErrorMessages} from '../../constants/errors';
import { setLoadingPayments, setPayments } from './payments-reducer';

export const getPayments = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        dispatch(setLoadingPayments(true));
        const {data}: AxiosResponse =
            await axiosInstance.get(PAYMENTS_LIST, {params});
        dispatch(setPayments(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingPayments(false));
    }
};
