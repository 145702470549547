import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface ISnackbarCloseButton {
  snackbarKey: any;
}

export function SnackbarCloseButton ({snackbarKey}:ISnackbarCloseButton) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <CloseIcon fontSize={'small'}  style={{ color: 'white' }}/>
        </IconButton>
    );
}
