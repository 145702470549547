import {createSlice, Draft} from '@reduxjs/toolkit';
import { IManager } from '../../interfaces/manager.interface';
import { IUserTotal } from '../../interfaces/user.interface';

export interface IManagerListState {
  managers: IManager[],
  count: number,
  total: IUserTotal,
  isLoading: boolean,
  error: string | null
}

const initialState: IManagerListState = {
    managers: [],
    count: 0,
    total: {
        totalInput: 0,
        totalOutput: 0,
        dirtyMoney: 0,
    },
    isLoading: false,
    error: null
};

export const managerListSlice = createSlice({
    name: 'managerList',
    initialState,
    reducers: {
        updateManagerAtTable:(state: Draft<IManagerListState>, {payload}) => {
            state.managers = state.managers.map(item=>{
                return item.id!==payload.id ?  item : {...item,...payload};
            });
        },
        setManagers: (state: Draft<IManagerListState>, {payload}) => {
            state.managers = payload.managers;
            state.count = payload.count;
            state.total = payload?.total ?? {totalInput: 0,totalOutput: 0};
        },
        clearManagers: (state: Draft<IManagerListState>) => {
            state.managers = [];
            state.count = 0;
            state.total = {
                totalInput: 0,
                totalOutput: 0,
                dirtyMoney: 0
            };
        },
        setLoadingManagers: (state: Draft<IManagerListState>, {payload}) => {
            state.isLoading = payload;
        },
        setManagersError: (state: Draft<IManagerListState>,  {payload}) => {
            state.error = payload;
        }
    }
});

export const {
    setManagers,
    setLoadingManagers,
    setManagersError ,
    updateManagerAtTable,
    clearManagers } = managerListSlice.actions;

export default managerListSlice.reducer;
