import {MenuItem, TextField} from '@mui/material';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {getRegions} from '../../store/regions/thunk';

export interface IRegionsSelect {
    register: any;
    errors: any;
    name?: string;
    value?: string | number;
    sx?: any;
    disabled?: boolean;
}

export const RegionSelect: React.FC<IRegionsSelect> = ({
    register,
    errors,
    name='regionId',
    sx,
    value,
    disabled=false
}) =>{
    const dispatch = useAppDispatch();
    const { data: regions, isLoading } = useAppSelector(state => state.regions);

    useEffect(()=>{
        if(regions.length===0 && !isLoading){
            dispatch(getRegions());
        }
    },[]);

    return (
        <TextField
            fullWidth={true}
            margin="normal"
            size="small"
            disabled={disabled}
            label="Регион"
            defaultValue={value ? value : ''}
            select
            sx={sx}
            error={!!errors[name]}
            helperText={errors[name] ? errors[name].message : null}
            id={name}
            {...register(name,
                {
                    required: {value: true, message: 'выберите регион'},
                })}
        >
            {regions && regions.map((region) => (
                <MenuItem key={'reg_'+region.id} value={region.id}>
                    {region.name}
                </MenuItem>
            ))}
        </TextField>
    );
};
