import React from 'react';
import { IRoute } from '../../interfaces/router/route.interface';
import { routersPath } from '../routes-path';
import { EUserRoles } from '../../enums/roles';

const ManagersPage = React.lazy(() => import('../../pages/admin/Managers'));
const ClientsPage = React.lazy(() => import('../../pages/Clients'));
const ManagerPage = React.lazy(() => import('../../pages/admin/Manager'));
const ClientPage = React.lazy(() => import('../../pages/admin/AdminClient'));
const InputPage = React.lazy(() => import('../../pages/admin/InputPage'));
const OutputPage = React.lazy(() => import('../../pages/admin/OutputPage'));
const ExpensesPage = React.lazy(() => import('../../pages/admin/ExpensesPage'));
const TransfersPage = React.lazy(
  () => import('../../pages/admin/TransfersPage'),
);
const SalaryPage = React.lazy(() => import('../../pages/admin/SalaryPage'));
const PencilPage = React.lazy(() => import('../../pages/admin/PencilPage'));
const SeniorsPage = React.lazy(() => import('../../pages/admin/SeniorsPage'));
const ReportsPage = React.lazy(() => import('../../pages/admin/ReportsPage/ReportsPage'));

const adminRoute = {
  roles: [EUserRoles.ADMIN],
  exact: true,
};

const adminSeniorRoute = {
  roles: [EUserRoles.ADMIN, EUserRoles.SENIOR],
  exact: true,
};

const forAllRoute = {
  roles: [EUserRoles.ADMIN, EUserRoles.SENIOR, EUserRoles.MANAGER],
  exact: true,
};

export const adminRoutes: IRoute[] = [
  {
    ...adminSeniorRoute,
    path: routersPath.ADMIN_DASHBOARD_MANAGERS,
    component: ManagersPage,
    title: 'Менеджеры',
  },
  {
    ...adminRoute,
    path: routersPath.ADMIN_DASHBOARD_SENIORS,
    component: SeniorsPage,
    title: 'Рег.менеджеры',
  },
  {
    ...adminSeniorRoute,
    path: routersPath.ADMIN_DASHBOARD_CLIENTS,
    component: ClientsPage,
    title: 'Клиенты',
  },
  {
    ...adminSeniorRoute,
    path: routersPath.ADMIN_CLIENT_PROFILE,
    component: ClientPage,
    title: 'Детали клиента',
    // roles:[EUserRoles.ADMIN],
  },
  {
    ...adminSeniorRoute,
    path: routersPath.ADMIN_MANAGER_PROFILE,
    component: ManagerPage,
    title: 'Детали менеджера',
  },
  {
    ...forAllRoute,
    path: routersPath.ADMIN_DASHBOARD_INPUT,
    component: InputPage,
    title: 'Пополнения',
  },
  {
    ...forAllRoute,
    path: routersPath.ADMIN_DASHBOARD_OUTPUT,
    component: OutputPage,
    title: 'Выводы',
  },
  {
    ...adminSeniorRoute,
    path: routersPath.ADMIN_DASHBOARD_EXPENSES,
    component: ExpensesPage,
    title: 'Расходы',
  },
  {
    ...adminRoute,
    path: routersPath.ADMIN_DASHBOARD_TRANSFERS,
    component: TransfersPage,
    title: 'Перевод между кошельками',
  },
  {
    ...adminRoute,
    path: routersPath.ADMIN_DASHBOARD_SALARY,
    component: SalaryPage,
    title: 'Зарплата',
  },
  {
    ...adminSeniorRoute,
    path: routersPath.ADMIN_DASHBOARD_PENCIL,
    component: PencilPage,
    title: 'Карандаш',
  },
  {
    ...adminSeniorRoute,
    path: routersPath.ADMIN_DASHBOARD_REPORTS,
    component: ReportsPage,
    title: 'Отчеты',
  },
];
