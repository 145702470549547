import { createSlice, Draft } from '@reduxjs/toolkit';
import { ITransactionLog } from './thunk';

export interface ITransactionManager{
  id: number;
  firstName: string;
  lastName: string;
}

export interface ITransactionState{
  data: ITransactionLog[],
  isLoading: boolean,
  error: string | null,
  count: number;
  tab: string | null;
}

const initialState: ITransactionState ={
    data: [],
    count: 0,
    isLoading: false,
    error: null,
    tab: null,
};

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers:{
        setTransaction: (state: Draft<ITransactionState>, {payload}) => {
            state.data = payload.transactions;
            state.count = payload.count;
        },
        setLoadingTransaction: (state: Draft<ITransactionState>, {payload}) => {
            state.isLoading = payload;
        },
        setTransactionError: (state: Draft<ITransactionState>,  {payload}) => {
            state.error = payload;
        },
        addTransaction: (state: Draft<ITransactionState>,  {payload}) => {
            if (payload.type === state.tab || state.tab === 'all') {
                state.data = [...payload, ...state.data];
                state.count = state.count + 1;
            }
        },
        setTransactionTab: (state: Draft<ITransactionState>,  {payload}) => {
            state.tab = payload;
        },
    }
});

export const {
    setTransaction,
    setLoadingTransaction,
    setTransactionError,
    addTransaction,
    setTransactionTab
} = transactionSlice.actions;

export default transactionSlice.reducer;
