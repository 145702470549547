import React from 'react';
import {UpdateTransactionInputForm} from './index';
import {useForm} from 'react-hook-form';
import {Box, Button, TextField, Typography} from '@mui/material';
// import {ManagerSelect} from '../../ManagerSelect';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import { usePermission } from '../../Permission';
import { EUserRoles } from '../../../enums/roles';
import DateSelect from '../../DateSelect';

interface Props {
    onCancel(): void;
    onSubmit(formData: UpdateTransactionInputForm): void;
    inputTransactionData: UpdateTransactionInputForm;
    isLoading: boolean;
}

export const UpdateInputTransactionForm: React.FC<Props> = ({
    onCancel,
    onSubmit,
    inputTransactionData,
    isLoading
}) => {
    const {register, handleSubmit, formState: { errors },getValues,setValue,watch} = useForm({
        defaultValues: {...inputTransactionData,payment: inputTransactionData.paymentInfo.id}
    });
    const isPermissions = usePermission([EUserRoles.ADMIN]);
    watch('createdAt');
    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography variant={'h5'} align={'center'}>Пополнение</Typography>
            {/*<ManagerSelect*/}
            {/*    sx={{m:'5px'}}*/}
            {/*    register={register}*/}
            {/*    errors={errors}*/}
            {/*    name={'managerId'}*/}
            {/*    defaultValue={inputTransactionData.managerId}*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    margin="normal"*/}
            {/*    sx={{m:'5px'}}*/}
            {/*    fullWidth*/}
            {/*    helperText={errors.city ? errors.city.message : null}*/}
            {/*    id="city"*/}
            {/*    label="Город"*/}
            {/*    error={!!errors.city}*/}
            {/*    required*/}
            {/*    size='small'*/}
            {/*    {...register('city', {*/}
            {/*        required: {value: true, message: 'введите город'},*/}
            {/*        minLength: {value: 2, message: 'минимальная длинна 2 символа' }*/}
            {/*    })}*/}
            {/*/>*/}
            <TextField
                sx={{m:'5px'}}
                disabled={!isPermissions}
                margin="normal"
                error={!!errors.crypto}
                helperText={errors.crypto ? errors.crypto.message : null}
                fullWidth
                size='small'
                label="Крипта"
                id="crypto"
                {...register('crypto',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{m:'5px'}}
                disabled={!isPermissions}
                margin="normal"
                error={!!errors.paymentId}
                helperText={errors.paymentId ? errors.paymentId.message : null}
                fullWidth
                size='small'
                label="ID платежа"
                id="paymentId"
                {...register('paymentId',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />

            <TextField
                margin="normal"
                sx={{m:'5px'}}
                disabled={!isPermissions}
                error={!!errors.dirtyMoney}
                helperText={errors.dirtyMoney ? errors.dirtyMoney.message : null}
                fullWidth
                size='small'
                type="number"
                label="Cумма грязными"
                id="dirtyMoney"
                {...register('dirtyMoney',{
                    setValueAs: v => v ? parseFloat(v) : v,
                })}
            />
            <TextField
                margin="normal"
                sx={{m:'5px'}}
                disabled={!isPermissions}
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <PaymentSelect
                sx={{m:'5px'}}
                disabled={!isPermissions}
                register={register}
                errors={errors}
                defaultValue={inputTransactionData.paymentInfo.id}
            />
            <TextField
                margin="normal"
                sx={{m:'5px'}}
                disabled={!isPermissions}
                error={!!errors.unrealisticAmounts}
                helperText={errors.unrealisticAmounts ? errors.unrealisticAmounts.message : null}
                size='small'
                fullWidth
                multiline
                rows={2}
                label="Накрутил вручную"
                id="unrealisticAmounts"
                {...register('unrealisticAmounts')}
            />
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value)}
                value={getValues('createdAt')}
                sx={{
                    width: '100%',
                    m:'5px',
                }}
                disabled={!isPermissions}
            />
            <TextField
                margin="normal"
                sx={{m:'5px'}}
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                id="comment"
                {...register('comment')}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Изменить
                </LoadingButton>
            </Box>
        </Box>
    </>;

};
