import React  from 'react';
import { Box, Modal } from '@mui/material';
import classes from '../modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';
import { EditClientForm } from './EditClientForm';
import { IClient } from '../../../interfaces/client.interface';
import { editClient } from '../../../store/client/thunk';

export const EditClient: React.FC = () =>{
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector(state => state.client);
    const { data } = useAppSelector(state => state.modal);

    const onCloseModal = () => dispatch(closeModal());

    const onSubmit = (formData: IClient) => {
        dispatch(editClient(
            formData,
            data?.updateList ? data.updateList : false,
            data?.getClients ? data?.getClients: null));
    };

    return (
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <EditClientForm
                    onCancel={onCloseModal}
                    onSubmit={onSubmit}
                    clientData={data.client}
                    isLoading={isLoading}
                />

            </Box>
        </Modal>
    );
};
