import React from 'react';
import { Avatar, Box, Button, TextField } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import { useForm } from 'react-hook-form';
import { IUser } from '../../../interfaces/user.interface';
import { RegionSelect } from '../../RegionSelect/RegionSelect';
import { PermissionHOC } from '../../Permission';
import { EUserRoles } from '../../../enums/roles';

interface EditManagerFormProps {
  onCancel(): void;
  onSubmit(formData: IUser): void;
  managerData: IUser | null;
  isLoading: boolean;
}

const initialData = {
    email: '',
    nickName: '',
    firstName: '',
    lastName: '',
    boss: '',
    rate: 0,
    phone: '',
    telegram: '',
    regionId: '',
    description: '',
    password: null,
    confirmPassword: null
};

function EditManagerForm ({onCancel, onSubmit, managerData, isLoading}: EditManagerFormProps) {
    const { register, handleSubmit, formState: { errors },getValues,watch } = useForm({
        defaultValues: managerData ? {
            ...managerData,
            regionId:managerData?.region.id.toString(),
            password: null,
            confirmPassword: null
        } : initialData
    });

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <PermContactCalendarIcon />
                </Avatar>
            </Box>

            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                fullWidth
                disabled={true}
                id="email"
                label="E-mail"
                autoComplete="email"
                size='small'
                {...register('email', {
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'некорректный адрес ел. почты'
                    }
                })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.nickName}
                helperText={errors.nickName ? errors.nickName.message : null}
                fullWidth
                size='small'
                label="Ник"
                autoComplete="nickName"
                id="nickName"
                {...register('nickName',
                    {
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : null}
                required
                fullWidth
                size='small'
                label="Имя"
                autoComplete="firstName"
                id="firstName"
                {...register('firstName',
                    {
                        required: {value: true, message: 'введите имя'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : null}
                required
                fullWidth
                size='small'
                label="Фамилия"
                autoComplete="lastName"
                id="lastName"
                {...register('lastName',
                    {
                        required: {value: true, message: 'введите фамилию'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.boss}
                helperText={errors.boss ? errors.boss.message : null}
                fullWidth
                size='small'
                label="Старший"
                autoComplete="boss"
                id="boss"
                {...register('boss',
                    {
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                sx={{mt: '5px', width: '100%'}}
                error={!!errors.rate}
                helperText={errors.rate ? errors.rate.message : null}
                fullWidth
                type="number"
                size='small'
                label="Ставка"
                id="bid"
                {...register('rate',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                    })}
            />
            <PermissionHOC roles={[EUserRoles.ADMIN]}>
                <RegionSelect
                    sx={{mt: '5px', width: '100%'}}
                    register={register}
                    errors={errors}
                    name={'regionId'}
                    value={getValues('regionId')}
                />
            </PermissionHOC>
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : null}
                // required
                fullWidth
                size='small'
                label="Телефон"
                autoComplete="phone"
                id="phone"
                {...register('phone',
                    {
                        // required: {value: true, message: 'введите телефон'},
                        maxLength: {value: 13, message: 'длинна не больше 13 символов'},
                        minLength: {value: 10, message: 'минимальная длинна 10 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.telegram}
                helperText={errors.telegram ? errors.telegram.message : null}
                fullWidth
                size='small'
                label="Телеграм"
                autoComplete="telegram"
                id="telegram"
                {...register('telegram',
                    {
                        maxLength: {value: 15, message: 'длинна не больше 15 символов'},
                        minLength: {value: 4, message: 'минимальная длинна 4 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : null}
                fullWidth
                multiline
                maxRows={2}
                size='small'
                label="Комментарий"
                autoComplete="description"
                id="description"
                {...register('description')}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : null}
                fullWidth
                size='small'
                label="Новый пароль"
                type="password"
                id="password"
                autoComplete="password"
                {...register('password',
                    {
                        maxLength: {value: 10, message: 'длинна не больше 10 символов'},
                        minLength: {value: 5, message: 'минимальная длинна 5 символа' },
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword.message : null}
                fullWidth
                size='small'
                label="Подтвердите пароль"
                type="password"
                id="confirmPassword"
                autoComplete="password"
                {...register('confirmPassword',
                    {
                        validate: (value)=> value ===watch('password') || 'Пароли не совпадают',
                    })}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    sx={{flexBasis: '45%'}}
                    variant="outlined">
                    Обновить
                </LoadingButton>
            </Box>
        </Box>
    );
}

export default EditManagerForm;
