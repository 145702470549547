import React from 'react';
import classes from './../modal.module.scss';
import { Box, Button, Modal } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';

function ConfirmModal () {
    const dispatch = useAppDispatch();
    const modal = useAppSelector(state => state.modal);
    const onCloseModal = () => dispatch(closeModal());

    return (
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            onClose={onCloseModal}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ my: 2 }} >
                    <p>Вы действительно хотите удалить запись?</p>
                </Box>
                <Box sx={{
                    display:'flex',
                    width: '100%',
                    justifyContent: 'space-around'
                }}>
                    <Button autoFocus onClick={onCloseModal}>
                        Отменить
                    </Button>
                    <Button variant="outlined" onClick={modal.data?.onConfirm}>Удалить</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default ConfirmModal;
