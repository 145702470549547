import { createSlice, Draft } from '@reduxjs/toolkit';

interface ISnackbarState {
  notifications: { [key: string]: any }[],
}

const initialState: ISnackbarState = {
    notifications: [],
};

export const ENQUEUE_SNACKBAR = 'enqueueSnackbar';
export const CLOSE_SNACKBAR = 'closeSnackbar';
export const REMOVE_SNACKBAR = 'removeSnackbar';

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        [ENQUEUE_SNACKBAR]: {
            reducer: (state: Draft<ISnackbarState>, { payload }) => {
                state.notifications.push(payload);
            },
            prepare (notification) {
                return {...notification, payload :{ ...notification, key: new Date().getTime() + Math.random() }};
            },
        },
        [CLOSE_SNACKBAR]: {
            reducer: (state: Draft<ISnackbarState>, action) => {
                const { payload } = action;
                state.notifications = state.notifications.map(notification => {
                    const shouldDismiss = payload.dismissAll || notification.key === payload.key;
                    return shouldDismiss ? { ...notification, dismissed: true } : { ...notification };
                });
            },
            prepare: (key) => {
                return { ...key, dismissAll: !key };
            }
        },
        [REMOVE_SNACKBAR]: (state: Draft<ISnackbarState>, { payload }) => {
            state.notifications = state.notifications.filter(notification => notification.key !== payload);
        }
    }

});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = snackbarSlice.actions;

export const showSnackbar = (text: string, variant: string) => enqueueSnackbar({
    message: text,
    options: {
        variant
    }
});

export default snackbarSlice.reducer;
