import {AppDispatch, store} from '../index';
import axiosInstance from '../../config/axios';
import {
    ADD_PENCIL,
    GET_PENCIL,
    GET_PENCIL_LOGS,
    REMOVE_PENCIL_LOGS, UPDATE_PENCIL_LOGS
} from '../../constants/api-endpoints';
import {closeModal} from '../modal/reducer';
import {showSnackbar} from '../snackbar/reducer';
import {AxiosResponse} from 'axios';
import {errorMapper} from '../../utils/errorMapper';
import {ErrorMessages} from '../../constants/errors';
import { setLoadingPencil, setPencil, setPencilLogs } from './pencil-reducer';

export interface IAddPencil {
    managersId:number[];
    value:number;
    hash: string | null;
    commentPencil: string | null;
}

export interface IUpdatePencilLog {
    id: number,
    value:number;
    hash: string | null;
    commentPencil: string | null;
}

export const getPencil = (params?: any) => async (dispatch: AppDispatch)=> {
    try {
        const {data}: AxiosResponse =
            await axiosInstance.get(GET_PENCIL, {params});
        dispatch(setPencil(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }finally {
        dispatch(setLoadingPencil(false));
    }
};

export const getPencilLog = (params?: any) => async (dispatch: AppDispatch)=> {
    try {
        const {data}: AxiosResponse =
            await axiosInstance.get(GET_PENCIL_LOGS, {params});
        dispatch(setPencilLogs(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }
};

export const addPencil = (payload: IAddPencil) => async (dispatch: AppDispatch)=> {
    const {modal: {data: {page}}} = store.getState();
    try{
        await axiosInstance.post(ADD_PENCIL, {...payload});
        dispatch(closeModal());
        if (page){
            dispatch(getPencilLog());
        } else {
            dispatch(getPencil());
        }
        dispatch(showSnackbar('Карандаш пополнен', 'success'));
    }catch (e) {
        dispatch(showSnackbar('Ошибка пополнения карандаша', 'error'));
    }
};

export const removePencilLogs = (id: number) => async (dispatch: AppDispatch)=> {
    const {modal: {data: {pagination,calendar}},pencil:{logs:{data}}} = store.getState();
    try {
        await axiosInstance.delete(REMOVE_PENCIL_LOGS+id);
        if((data.length-1)===0){
            dispatch(getPencilLog({
                pageSize:pagination.perPage,page: pagination.page,calendar
            },));
        } else {
            dispatch(getPencilLog({
                pageSize:pagination.perPage,page: pagination.page+1,calendar
            },));
        }
        dispatch(closeModal());
        dispatch(showSnackbar('Запись удалена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }
};

export const updatePencilLogs = ({id,...payload}: IUpdatePencilLog) => async (dispatch: AppDispatch)=> {
    const {modal: {data: {pagination,calendar}}} = store.getState();
    try {
        await axiosInstance.patch(UPDATE_PENCIL_LOGS + id,{...payload});
        dispatch(getPencilLog({
            pageSize:pagination.perPage,page: pagination.page,calendar
        },));
        dispatch(closeModal());
        dispatch(showSnackbar('Запись обновлена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }
};
