import {combineReducers, configureStore} from '@reduxjs/toolkit';
import modalReducer from './modal/reducer';
import authReducer from './auth/reducer';
import managerListReducer from './manager/manager-list-reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore} from 'redux-persist';
import managerReducer from './manager/manager-reducer';
import clientReducer from './client/client-reducer';
import clientListReducer from './client/client-list-reducer';
import enqueueSnackbarReducer from './snackbar/reducer';
import transactionReducer from './transaction/reducer';
import regionsReducer from './regions/regions-reducer';
import paymentsReducer from './payments/payments-reducer';
import expensesReducer from './expenses/expenses-reducer';
import transfersReducer from './transfers/transfers-reducer';
import managerLightReducer from './managerLight/managerLight-reducer';
import inputTransactionReducer from './input-transaction/reducer';
import outputTransactionReducer from './output-transaction/reducer';
import currencyReducer from './currency/currency-reducer';
import salaryReducer from './salary/salary-reducer';
import clientLightReducer from './client/client-light-reducer';
import weeksReducer from './weeks/weeks-reducer';
import pencilReducer from './pencil/pencil-reducer';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['auth']
};

const rootReducer = combineReducers({
    modal: modalReducer,
    auth: authReducer,
    managerList: managerListReducer,
    manager: managerReducer,
    client: clientReducer,
    clientList: clientListReducer,
    enqueueSnackbar: enqueueSnackbarReducer,
    transaction: transactionReducer,
    regions: regionsReducer,
    payments: paymentsReducer,
    expenses: expensesReducer,
    transfers: transfersReducer,
    managerLight: managerLightReducer,
    inputTransactions: inputTransactionReducer,
    outputTransactions: outputTransactionReducer,
    currency: currencyReducer,
    salary: salaryReducer,
    clientLight: clientLightReducer,
    weeks: weeksReducer,
    pencil: pencilReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

//todo: fix ignoredActions
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
