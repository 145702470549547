import React from 'react';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';
import {useAppDispatch} from '../../store/hooks';
import {authLogout} from '../../store/auth/thunk';

interface IProfileAvatar {
    openProfileModal(): void;
}

export const ProfileAvatar: React.FC<IProfileAvatar> = ({openProfileModal}) =>{
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenProfileModal = () =>{
        openProfileModal();
        setAnchorEl(null);
    };
    const handleLogout = () =>{
        dispatch(authLogout());
        setAnchorEl(null);
    };
    return <>
        <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
        >
            <AccountCircle />
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleOpenProfileModal}>Профиль</MenuItem>
            <MenuItem onClick={handleLogout}>Выход</MenuItem>
        </Menu>
    </>;
};
