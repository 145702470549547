import React, {useEffect, useState} from 'react';
import {useAppDispatch} from '../../../store/hooks';
import {closeModal} from '../../../store/modal/reducer';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, Modal, TextField} from '@mui/material';
import classes from '../modal.module.scss';
import AutocompleteField from '../../AutocompleteField';
import {ManagerLight} from '../TransactionInputPage/TransactionInputFormPage';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../../config/axios';
import {MANAGERS_LIGHT_LIST} from '../../../constants/api-endpoints';
import Loader from '../../Loader';
import { addPencil } from '../../../store/pencil/thunks-pencil';

export interface IPencilForm {
    value: number | null;
    managersId: ManagerLight[] | null;
    commentPencil: string | null;
    hash: string | null;
}

const initialData: IPencilForm = {
    value: null,
    managersId: null,
    commentPencil: null,
    hash: null,
};


const PencilCreateModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const onCloseModal = () => dispatch(closeModal());
    const [managers, setManagers] = useState<ManagerLight[] | null>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm({
        defaultValues:{
            ...initialData
        }
    });

    useEffect(()=>{
        try{
            const getManagersList = async () =>{
                const {data: managersData}: AxiosResponse =
                    await axiosInstance.get(MANAGERS_LIGHT_LIST);
                return managersData ? managersData : null;
            };
            getManagersList().then((r:any)=>{
                setManagers(r);
            });

        } catch {
            setManagers(null);
            setValue('managersId',null);
        }
    },[]);

    const onSubmit = (data:IPencilForm) => {
        const {managersId, value, hash,commentPencil} = data;
        if(managersId && value){
            dispatch(addPencil({
                managersId: managersId?.map(el=>el.id),
                hash,
                commentPencil,
                value
            }));
        }
    };

    return (
        <Modal
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <p>Пополнение карандаша</p>
                </Box>
                {managers ? (<>
                    <Controller
                        control={control}
                        name={'managersId'}
                        render={({ field: { onChange } }) => (
                            <AutocompleteField
                                multiple={true}
                                onChangeRender={onChange}
                                targetValue={'managersId'}
                                label="Менеджеры"
                                sx={{width: '100%'}}
                                dataOptions={managers.map(el=>({id:el.id, nickName: el.nickName}))}
                                displayValue1="nickName"
                                inputProps={{
                                    ...register('managersId',
                                        {
                                            required: {value: true, message: 'выберите менеджера'},
                                        }),
                                    error: !!errors.managersId,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore:next-line
                                    helperText:  errors.managersId ? errors.managersId?.message : null

                                }}
                            />)}
                    />
                    <TextField
                        margin="normal"
                        sx={{m:'5px 0'}}
                        error={!!errors.commentPencil}
                        helperText={errors.commentPencil ? errors.commentPencil.message : null}
                        fullWidth
                        size='small'
                        label="Комментарий"
                        autoComplete="comment"
                        id="comment"
                        {...register('commentPencil',{})}
                    />
                    <TextField
                        margin="normal"
                        sx={{m:'5px 0'}}
                        error={!!errors.hash}
                        helperText={errors.hash ? errors.hash.message : null}
                        fullWidth
                        size='small'
                        label="Хеш"
                        autoComplete="hash"
                        id="hash"
                        {...register('hash',{})}
                    />
                    <TextField
                        margin="normal"
                        sx={{m:'5px 0'}}
                        error={!!errors.value}
                        helperText={errors.value ? errors.value.message : null}
                        fullWidth
                        required
                        size='small'
                        type="number"
                        label="Cумма"
                        id="value"
                        {...register('value',{
                            setValueAs: v => v ? parseFloat(v) : v,
                            required: {value: true, message: 'введите сумму'},
                        })}
                    />
                </>
                ) : <Loader />}
                <Box sx={{
                    display:'flex',
                    width: '100%',
                    justifyContent: 'space-around'
                }}>
                    <Button autoFocus onClick={onCloseModal}>
                        Отменить
                    </Button>
                    <Button
                        variant="outlined"
                        type={'submit'}
                    >Пополнить</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PencilCreateModal;
