import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {Controller, useForm} from 'react-hook-form';
import { Avatar, Box, Button, TextField } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { createTransaction } from '../../../store/transaction/thunk';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import AutocompleteField from '../../AutocompleteField';

export enum PaymentType {
  input=1,
  output=2
}

export interface ITransactionInputForm {
  clientId: number | null,
  managersId: any[] | null,
  crypto: string | null,
  dirtyMoney: number | null,
  revision: number | null,
  payment: string | null,
  paymentId: string | null,
  type: PaymentType,
  unrealisticAmounts: string | null,
  comment: string |null,
  createdAt: string | null,
}

const initialData: ITransactionInputForm = {
    clientId: null,
    revision: null,
    managersId: null,
    crypto:null,
    dirtyMoney:null,
    payment: '',
    paymentId: null,
    comment: null,
    type: PaymentType.input,
    unrealisticAmounts: null,
    createdAt: null,
};

interface TransactionInputFormProps {
  onCancel(): void
}

export const TransactionInputForm: React.FC<TransactionInputFormProps> = ({onCancel}) =>{
    const dispatch = useAppDispatch();
    const { data: client } = useAppSelector(state => state.client);
    const { isLoading } = useAppSelector(state => state.transaction);
    const {register,
         handleSubmit,
          formState: { errors },
          control} = useForm<ITransactionInputForm>({
        defaultValues: {
            ...initialData,
            clientId: client?.id,
            managersId: client?.managers,
        }
    });

    const onSubmit = (data: ITransactionInputForm) => {
        if(!data.dirtyMoney)data.dirtyMoney=null;
        if(data.managersId)data.managersId = data.managersId.map(el=>el.id);
        dispatch(createTransaction(data));
    };

    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems:'center'
            }}>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <PermContactCalendarIcon />
                </Avatar>
                <div style={{marginTop: '5px'}}>{client?.nickName}</div>
            </Box>
            {client && <Controller
                control={control}
                name={'managersId'}
                render={({ field: { onChange } }) => (
                    <AutocompleteField
                        multiple={true}
                        onChangeRender={onChange}
                        targetValue={'id'}
                        label="Менеджеры"
                        sx={{mt: '15px', width: '100%'}}
                        dataOptions={client.managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        displayValue1="nickName"
                        defaultValue={client.managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        inputProps={{
                            ...register('managersId',
                                {
                                    required: {value: true, message: 'выберите менеджера'},
                                }),
                            error: !!errors.managersId,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore:next-line
                            helperText:  errors.managersId ? errors.managersId?.message : null

                        }}
                    />)}
            /> }
            <TextField
                sx={{fontSize:'5px'}}
                margin="normal"
                error={!!errors.crypto}
                helperText={errors.crypto ? errors.crypto.message : null}
                fullWidth
                size='small'
                label="Крипта"
                id="crypto"
                {...register('crypto',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{fontSize:'5px'}}
                margin="normal"
                error={!!errors.paymentId}
                helperText={errors.paymentId ? errors.paymentId.message : null}
                fullWidth
                size='small'
                label="ID платежа"
                id="paymentId"
                {...register('paymentId',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />

            <TextField
                margin="normal"
                error={!!errors.dirtyMoney}
                helperText={errors.dirtyMoney ? errors.dirtyMoney.message : null}
                fullWidth
                size='small'
                type="number"
                label="Cумма грязными"
                id="dirtyMoney"
                {...register('dirtyMoney',{
                    setValueAs: v => v ? parseFloat(v) : v,
                })}
            />
            <TextField
                margin="normal"
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <PaymentSelect register={register} errors={errors} />
            <TextField
                margin="normal"
                error={!!errors.unrealisticAmounts}
                helperText={errors.unrealisticAmounts ? errors.unrealisticAmounts.message : null}
                size='small'
                fullWidth
                multiline
                rows={2}
                label="Накрутил вручную"
                id="unrealisticAmounts"
                {...register('unrealisticAmounts')}
            />
            <TextField
                margin="normal"
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                id="comment"
                {...register('comment')}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                {client && <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Пополнить
                </LoadingButton>}
            </Box>
        </Box>
    </>;

};
