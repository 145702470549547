import React, {ReactNode} from 'react';
import {Header} from '../../components/Header/Header';

interface DashboardProps {
    title?: string;
    children?: ReactNode
}

export const Dashboard: React.FC<DashboardProps> = (props) => {

    return (
        <>
            <Header title={props.title}/>
            {props.children}
        </>
    );
};
