import React, { useEffect, useState } from 'react';
import { getClientsLight } from '../../store/client/thunk';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import AutocompleteField from '../AutocompleteField';
import { Controller } from 'react-hook-form';

export interface IPaymentSelect {
    register: any;
    errors: any;
    name?: string;
    sx?: any;
    managerId?: any;
    disabled?: boolean;
    control?: any;
}

export const ClientSelect: React.FC<IPaymentSelect> = ({
    sx,
    register,
    errors,
    name='clientId',
    managerId,
    // disabled= false,
    control
}) =>{
    const dispatch = useAppDispatch();
    const { data: clientsLight, isLoading } = useAppSelector(state => state.clientLight);
    const [key, setKey] = useState('client');

    useEffect(()=>{
        if(managerId){
            dispatch(getClientsLight({managerId: managerId?.id}));
            setKey(managerId?.id);
        } else {
            dispatch(getClientsLight());
        }
    },[managerId?.id]);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange } }) => (
                <AutocompleteField
                    sx={sx}
                    onChangeRender={onChange}
                    label="Клиент"
                    key={key}
                    isLoading={isLoading}
                    dataOptions={clientsLight}
                    displayValue1={'nickName'}
                    targetValue={'id'}
                    inputProps={{
                        ...register(name,
                            {
                                required: {value: true, message: 'выберите клиента'},
                            }),
                        error: !!errors[name],
                        helperText: errors[name] ? errors[name].message : null
                    }}
                />
            )}
        />
    );
};
