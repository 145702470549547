import { AppDispatch } from '../index';
import {addTransaction, ITransactionManager, setLoadingTransaction, setTransaction} from './reducer';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../config/axios';
import { TRANSACTION_CLIENT_LIST, TRANSACTION_CREATE } from '../../constants/api-endpoints';
import { showSnackbar } from '../snackbar/reducer';
import { errorMapper } from '../../utils/errorMapper';
import { ErrorMessages } from '../../constants/errors';
import { IClient } from '../../interfaces/client.interface';
import { ResponseMessage } from '../../constants/response.messages';
import { PaymentType } from '../../components/modals/TransactionInput/TransactionInputForm';
import { closeModal } from '../modal/reducer';
import { getClient } from '../client/thunk';

interface BaseInterface{
  [key: string]: any;
}

export interface ITransaction extends BaseInterface{
  clientId: number | null,
  revision: number | null,
  managersId: number[] | null,
  crypto?: string | null,
  dirtyMoney?: number | null,
  payment: string | null,
  paymentId?: string | null,
  comment?: string | null,
  type: PaymentType,
  unrealisticAmounts?: string | null,
  commission?: number | null,
  amountWithCommission?: number | null,
  numberWallet?: string | null,
  manager?: ITransactionManager;

}

export interface ITransactionLog extends ITransaction{
  id: number,
  prev_balance: number,
  paymentInfo: {
      id: number;
      name: string
  } | null,
  balance: number,
  createdAt: string,
  updatedAt: string,
}

export interface IResponseTransaction{
  client: IClient;
}

interface IParams {
  page?: number;
  pageSize?: number;
  [key: string]: any;
}

export const getClientTransactions = (params: IParams) => async (dispatch: AppDispatch)=>{
    try{
        dispatch(setLoadingTransaction(true));
        const {data}: AxiosResponse = await axiosInstance.get(TRANSACTION_CLIENT_LIST+params.clientId, {
            params
        });
        dispatch(setTransaction(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_LIST_FAILED,e), 'error'));
        console.log(e);
    } finally {
        dispatch(setLoadingTransaction(false));
    }
};

export const createTransaction = (data:ITransaction) =>
    async (dispatch: AppDispatch)=>{
        const {clientId, ...payload} = data;
        try{
            dispatch(setLoadingTransaction(true));
            const {data}: AxiosResponse<ITransactionLog> =
          await axiosInstance.post(
              `${TRANSACTION_CREATE}/${clientId}`,
              payload);
            dispatch(addTransaction(data));
            dispatch(showSnackbar(ResponseMessage.TRANSACTION_SUCCESS, 'success'));
            dispatch(getClient({id: clientId}));
            dispatch(closeModal());
        }catch (e) {
            dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_CREATE_FAILED,e), 'error'));
            console.log(e);
        }finally {
            dispatch(setLoadingTransaction(false));
        }
    };
