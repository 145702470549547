import React, { useMemo } from 'react';
import {Box, Drawer, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {appHistory} from '../../router/history';
import { useAppSelector } from '../../store/hooks';
import { IAuth } from '../../store/auth/reducer';
import {EUserRoles} from '../../enums/roles';
import { adminItems, managerItems, seniorItems } from './menuList';
// import LogoutIcon from '@mui/icons-material/Logout';
// import LoginIcon from '@mui/icons-material/Login';

interface ISidebar {
    isOpenDrawer: boolean;
    handleDrawerClose(): void;
}

export const Sidebar: React.FC<ISidebar> = ({isOpenDrawer,handleDrawerClose}) =>{

    const { user } = useAppSelector<IAuth>(state => state.auth);

    const menuItems = useMemo(()=>{
        switch (user?.role){
        case EUserRoles.ADMIN:
            return adminItems;
        case EUserRoles.SENIOR:
            return seniorItems;
        case EUserRoles.MANAGER:
            return managerItems;
        default:
            return managerItems;
        }
    },[user]);

    return <>
        <Drawer
            anchor='left'
            open={isOpenDrawer}
            onClose={handleDrawerClose}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
            >
                <List>
                    {menuItems.map((item) => (
                        <ListItem button key={item.title} onClick={()=>{
                            appHistory.push(item.path);
                            handleDrawerClose();
                        }}>
                            <ListItemIcon>
                                <item.icon />
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    </>;
};
