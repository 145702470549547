import React from 'react';
import {useAppDispatch} from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';
import { Box, Modal } from '@mui/material';
import classes from '../modal.module.scss';
import { TransactionOutputPageForm } from './TransactionOutputFormPage';

export const TransactionOutputPage = () =>{

    const dispatch = useAppDispatch();
    const onCloseModal = () => dispatch(closeModal());
    return(
        <>
            <Modal
                sx={{
                    position:'absolute',
                    overflow:'auto',
                    height:'100%',
                }}
                open={true}
                className={classes.container}
            >
                <Box
                    className={classes.wrapper}
                    sx={{
                        position: 'inherit',
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <TransactionOutputPageForm  onCancel={onCloseModal} />
                </Box>
            </Modal>
        </>
    );
};
