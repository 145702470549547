import {createSlice, Draft} from '@reduxjs/toolkit';
import {getWeeksArray} from '../../utils/date';
import {formatISO} from 'date-fns';
import {weeksCountStartDate} from '../../constants/dates';
export interface IWeek {
    id: number;
    from: string;
    to: string;
}

export interface IWeeksState {
    rows: IWeek[],
    count: number,
}

const rowsData = getWeeksArray([],1,
    formatISO(new Date((new Date()).toISOString())),
    formatISO(new Date(weeksCountStartDate)));

const initialState : IWeeksState = {
    rows: rowsData,
    count: rowsData.length
};

export const weeksSlice = createSlice({
    name: 'weeks',
    initialState,
    reducers: {
        createWeeks: (state:Draft<IWeeksState>)=>{
            state.rows=rowsData;
            state.count=rowsData.length;
        }
    }
});

export const {
    createWeeks
} = weeksSlice.actions;

export default weeksSlice.reducer;
