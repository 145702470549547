import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {closeModal} from '../../../store/modal/reducer';
import {Box, Button, Modal, TextField} from '@mui/material';
import classes from '../modal.module.scss';
import {useForm} from 'react-hook-form';
import {IUpdatePencilLog, updatePencilLogs } from '../../../store/pencil/thunks-pencil';

const UpdatePencilLog = () =>{

    const dispatch = useAppDispatch();
    const {data: {log}} = useAppSelector(state => state.modal);
    const onCloseModal = () => dispatch(closeModal());
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            id: log.id,
            hash: log.hash,
            commentPencil: log.commentPencil,
            value: log.value,
        }
    });

    const onSubmit = (formData: IUpdatePencilLog) =>{
        const { value } = formData;
        dispatch(updatePencilLogs({
            ...formData,
            value: value ? value : 0}));
    };

    return (
        <Modal
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <p>Изменение карандаша</p>
                </Box>
                <TextField
                    margin="normal"
                    sx={{m:'5px 0'}}
                    fullWidth
                    size='small'
                    label="Менеджер"
                    value={log.manager.nickName}
                    disabled
                />
                <TextField
                    margin="normal"
                    sx={{m:'5px 0'}}
                    error={!!errors.commentPencil}
                    helperText={errors.commentPencil ? errors.commentPencil.message : null}
                    fullWidth
                    size='small'
                    label="Комментарий"
                    autoComplete="commentPencil"
                    id="commentPencil"
                    {...register('commentPencil',{})}
                />
                <TextField
                    margin="normal"
                    sx={{m:'5px 0'}}
                    error={!!errors.hash}
                    helperText={errors.hash ? errors.hash.message : null}
                    fullWidth
                    size='small'
                    label="Хеш"
                    autoComplete="hash"
                    id="hash"
                    {...register('hash',{})}
                />
                <TextField
                    margin="normal"
                    sx={{m:'5px 0'}}
                    error={!!errors.value}
                    helperText={errors.value ? errors.value.message : null}
                    fullWidth
                    required
                    size='small'
                    type="number"
                    label="Cумма"
                    id="value"
                    {...register('value',{
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
                />
                <Box sx={{
                    display:'flex',
                    width: '100%',
                    justifyContent: 'space-around'
                }}>
                    <Button autoFocus onClick={onCloseModal}>
                        Отменить
                    </Button>
                    <Button
                        variant="outlined"
                        type={'submit'}
                    >Обновить</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UpdatePencilLog;
