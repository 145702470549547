import React from 'react';
import {Box, Modal} from '@mui/material';
import classes from './../modal.module.scss';
import { ProfileForm } from './ProfileForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {closeModal} from '../../../store/modal/reducer';
import { IUser } from '../../../interfaces/user.interface';
import { updateProfile } from '../../../store/auth/thunk';


export const ProfileModal: React.FC = () =>{
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.auth);

    const onCloseModal = () => dispatch(closeModal());

    const onSubmit = (formData: IUser) => {
        dispatch(updateProfile(formData));
    };

    return <>
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            onClose={onCloseModal}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <ProfileForm
                    onCancel={onCloseModal}
                    onSubmit={onSubmit}
                    managerData={user}
                />
            </Box>
        </Modal>
    </>;
};
