import { createSlice, Draft } from '@reduxjs/toolkit';
import { IOutputTransaction } from '../../interfaces/output-transaction.interface';

export interface IOutputTransactionState {
  data: IOutputTransaction[],
  count: number;
  total: number;
  totalAmountWithCommission: number;
  totalDirtyMoney: number;
  totalCommission: number;
  isLoading: boolean;
}

const initialState: IOutputTransactionState = {
    data: [],
    count: 0,
    total: 0,
    totalAmountWithCommission: 0,
    totalCommission: 0,
    totalDirtyMoney: 0,
    isLoading: true
};

export const outputTransactionSlice = createSlice({
    name: 'outputTransaction',
    initialState,
    reducers:{
        setOutputTransaction: (state: Draft<IOutputTransactionState>, {payload}) => {
            state.data = payload.data;
            state.count = payload.count;
            state.totalAmountWithCommission = payload.totalAmountWithCommission;
            state.totalDirtyMoney = payload.totalDirtyMoney;
            state.total = payload.total;
            state.totalCommission = payload.totalCommission;
        },
        setLoadingOutputTransaction: (state: Draft<IOutputTransactionState>, {payload}) => {
            state.isLoading = payload;
        },
    }
});

export const {
    setOutputTransaction,
    setLoadingOutputTransaction
} = outputTransactionSlice.actions;

export default outputTransactionSlice.reducer;
