import { createTheme, ThemeProvider } from '@mui/material';
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import { Router } from 'react-router';
import classes from './RouterComponent.module.scss';
import { appHistory } from './history';
import {privateRoutes, publicRoutes} from './routers';
import {IRoute} from '../interfaces/router/route.interface';
import PrivateRoute from './PrivateRoute';
import NotFound from '../pages/NotFound';

const theme = createTheme();

export const RouterComponent: React.FC = () => {

    return <ThemeProvider theme={theme}>
        <div className={classes.main}>
            <Router history={appHistory}>
                <Switch>
                    {publicRoutes.map(
                        (route: IRoute) => (
                            <Route key={route.path}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        ),
                    )}
                    {privateRoutes.map(
                        (route: IRoute) => (
                            <PrivateRoute
                                key={route.path}
                                path={route.path}
                                exact={route.exact}
                                roles={route?.roles}
                                component={route.component}
                                title={route.title}
                            />
                        ),
                    )}
                    <Route path="*" component={NotFound}/>
                </Switch>
            </Router>
        </div>
    </ThemeProvider>;
};
