import {
    endOfDay,
    format,
    formatISO,
    isAfter,
    isSunday,
    nextSunday,
    previousMonday,
    previousSunday,
    startOfDay
} from 'date-fns';

export function formatDateString (dateString: string, displayFormat: string) {
    return format(Date.parse(dateString), displayFormat);
}

export const getWeeksArray = (arr: any[], id: number, prevWeekEnd: string, endDate: string) : any[]=>{
    if(isAfter(new Date(endDate),new Date(prevWeekEnd))) return arr;
    const endWeek =
        isSunday(new Date(prevWeekEnd)) ?
            formatISO(endOfDay(new Date(prevWeekEnd))) :
            formatISO(endOfDay(nextSunday(new Date(prevWeekEnd))));
    arr.push({
        id,
        from: formatISO(new Date(startOfDay(previousMonday(new Date(endWeek))))),
        to: endWeek,
    });
    return getWeeksArray(arr,++id,formatISO(endOfDay(previousSunday(new Date(endWeek)))),endDate);
};
