import React, { Suspense } from 'react';
import './App.css';
import {RouterComponent} from './router/RouterComponent';
import useNotifier from './hooks/useNotifier';
import Loader from './components/Loader';

const App: React.FC = () => {
    useNotifier();
    return (
        <>
            <Suspense fallback={<Loader center />}>
                <RouterComponent />
            </Suspense>
        </>
    );
};

export default App;
