import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {closeModal} from '../../../store/modal/reducer';
import {Box, Button, Modal, TextField, Typography} from '@mui/material';
import classes from '../modal.module.scss';
import {useForm} from 'react-hook-form';
import {ISalaryLogUpdate, updateSalaryLogs } from '../../../store/salary/thunk';

const UpdateSalaryLog = () =>{

    const dispatch = useAppDispatch();
    const {data: {log}} = useAppSelector(state => state.modal);
    const onCloseModal = () => dispatch(closeModal());
    const { register, handleSubmit, formState: { errors }} = useForm({
        defaultValues: {
            id: log.id,
            user: `${log.user.firstName} ${log.user.lastName}`,
            region: log.region.name,
            exchangeRate: log.exchangeRate,
            salary: log.salary,
        }
    });
    const onSubmit = (formData: ISalaryLogUpdate) =>{
        dispatch(updateSalaryLogs({
            id: formData.id,
            exchangeRate: formData.exchangeRate,
            salary: formData.salary
        }));
    };

    return (
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                    <Typography sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center'
                    }} variant={'h6'}>Редактировать выплату</Typography>
                    <TextField
                        margin="normal"
                        disabled={true}
                        fullWidth
                        size='small'
                        label="Менеджер"
                        id="user"
                        {...register('user',{})}
                    />
                    <TextField
                        margin="normal"
                        disabled={true}
                        fullWidth
                        size='small'
                        label="Регион"
                        id="region"
                        {...register('region',{})}
                    />
                    <TextField
                        margin="normal"
                        error={!!errors.exchangeRate}
                        helperText={errors.exchangeRate ? errors.exchangeRate.message : null}
                        required
                        fullWidth
                        type="number"
                        size='small'
                        label="Курс"
                        id="exchangeRate"
                        {...register('exchangeRate',
                            {
                                setValueAs: v => v ? Number(v) : v,
                                required: {value: true, message: 'введите курс'},
                            }
                        )}
                    />
                    <TextField
                        margin="normal"
                        error={!!errors.salary}
                        helperText={errors.salary ? errors.salary.message : null}
                        required
                        fullWidth
                        type="number"
                        size='small'
                        label="Зарплата"
                        id="salary"
                        {...register('salary',
                            {
                                setValueAs: v => v ? parseFloat(v) : v,
                                required: {value: true, message: 'введите зарплату'},
                            }
                        )}
                    />
                    <Box sx={{
                        display:'flex',
                        justifyContent: 'space-between',
                        mt:2,
                        width: '100%'
                    }}>
                        <Button
                            onClick={onCloseModal}
                            fullWidth
                            variant='outlined'
                            sx={{flexBasis: '45%'}}
                        >
                            Отмена
                        </Button>
                        <Button
                            fullWidth
                            type="submit"
                            variant='outlined'
                            sx={{flexBasis: '45%'}}
                        >
                            Изменить
                        </Button>
                    </Box>

                </Box>


            </Box>
        </Modal>
    );
};

export default UpdateSalaryLog;
