import React from 'react';
import {useForm} from 'react-hook-form';
import {Box, Button, TextField, Typography} from '@mui/material';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import { UpdateTransactionOutputForm } from '.';
import { usePermission } from '../../Permission';
import { EUserRoles } from '../../../enums/roles';
import DateSelect from '../../DateSelect';

interface Props {
    onCancel(): void;
    onSubmit(formData: UpdateTransactionOutputForm): void;
    outputTransactionData: UpdateTransactionOutputForm;
    isLoading: boolean;
}

export const UpdateOutputTransactionForm: React.FC<Props> = ({
    onCancel,
    onSubmit,
    outputTransactionData,
    isLoading
}) => {
    const {register, handleSubmit, formState: { errors },getValues,setValue,watch} = useForm({
        defaultValues: {...outputTransactionData,payment: outputTransactionData.paymentInfo.id}
    });
    const isPermissions = usePermission([EUserRoles.ADMIN]);
    watch('createdAt');
    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography variant={'h5'} align={'center'}>Вывод</Typography>
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                disabled={!isPermissions}
                error={!!errors.commission}
                helperText={errors.commission ? errors.commission.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Коммисия"
                id="commission"
                {...register('commission',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                    })}
            />
            <TextField
                margin="normal"
                disabled={!isPermissions}
                sx={{m:'5px 0'}}
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                inputProps={{
                    step: '0.01'
                }}
                size='small'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <PaymentSelect
                sx={{m:'5px 0'}}
                disabled={!isPermissions}
                register={register}
                errors={errors}
                defaultValue={outputTransactionData.paymentInfo.id}
            />
            <TextField
                sx={{m:'5px 0'}}
                disabled={!isPermissions}
                margin="normal"
                error={!!errors.numberWallet}
                helperText={errors.numberWallet ? errors.numberWallet.message : null}
                fullWidth
                size='small'
                label="Номер кошелька"
                id="numberWallet"
                {...register('numberWallet',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{m:'5px 0'}}
                disabled={!isPermissions}
                margin="normal"
                error={!!errors.paymentId}
                helperText={errors.paymentId ? errors.paymentId.message : null}
                fullWidth
                size='small'
                label="ID платежа"
                id="paymentId"
                {...register('paymentId',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />

            {/*<TextField*/}
            {/*    margin="normal"*/}
            {/*    sx={{m:'5px'}}*/}
            {/*    error={!!errors.dirtyMoney}*/}
            {/*    helperText={errors.dirtyMoney ? errors.dirtyMoney.message : null}*/}
            {/*    fullWidth*/}
            {/*    size='small'*/}
            {/*    type="number"*/}
            {/*    label="Cумма грязными"*/}
            {/*    id="dirtyMoney"*/}
            {/*    {...register('dirtyMoney',{*/}
            {/*        setValueAs: v => v ? parseInt(v) : v,*/}
            {/*    })}*/}
            {/*/>*/}
            {/*<TextField*/}
            {/*    margin="normal"*/}
            {/*    sx={{m:'5px'}}*/}
            {/*    error={!!errors.unrealisticAmounts}*/}
            {/*    helperText={errors.unrealisticAmounts ? errors.unrealisticAmounts.message : null}*/}
            {/*    size='small'*/}
            {/*    fullWidth*/}
            {/*    multiline*/}
            {/*    rows={2}*/}
            {/*    label="Накрутил вручную"*/}
            {/*    id="unrealisticAmounts"*/}
            {/*    {...register('unrealisticAmounts')}*/}
            {/*/>*/}
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value)}
                value={getValues('createdAt')}
                sx={{
                    margin: '5px 0'
                }}
                disabled={!isPermissions}
            />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                id="comment"
                {...register('comment')}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Изменить
                </LoadingButton>
            </Box>
        </Box>
    </>;

};
