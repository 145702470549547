export const ErrorMessages = {
    GET_DATA: 'Ошибка при получении данных',
    CREATE_DATA: 'Ошибка при сохранении данных',
    UPDATE_DATA: 'Ошибка при обновлее данных',
    REMOVE_DATA: 'Ошибка при удалении данных',
    AUTH_FAILED: 'Ошибка при авторизации',
    LOGS_FAILED: 'Ошибка получения логов',
    TRANSACTION_LIST_FAILED: 'Ошибка получения списка транзакций',
    TRANSACTION_CREATE_FAILED: 'Ошибка создании транзакции',
    CLIENT_LOGS_GET_ERROR: 'Ошибка получения логов клиента',
};
