import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, TextField, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import {getRegions} from '../../../store/regions/thunk';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import AutocompleteField from '../../AutocompleteField';
import {addExpenses} from '../../../store/expenses/thunk';
import {PermissionHOC, usePermission} from '../../Permission';
import {EUserRoles} from '../../../enums/roles';
import DateSelect from '../../DateSelect';

export interface IAddExpenseForm {
    regionId: any[] | null;
    revision: number | null;
    commission: number | null;
    paymentId: number | null;
    comment: string | null;
    transactionId: string | null;
    createdAt: string | null;
}

const initialData: IAddExpenseForm = {
    regionId: null,
    revision: null,
    commission: null,
    paymentId: null,
    comment: null,
    transactionId: '',
    createdAt: null,
};

interface AddExpenseFormProps {
    onCancel(): void
}
export const AddExpenseForm: React.FC<AddExpenseFormProps> =({onCancel})=>{
    const dispatch = useAppDispatch();
    const {data} = useAppSelector(state => state.regions);
    const {user} = useAppSelector(state => state.auth);

    const {isLoading} = useAppSelector(state => state.expenses);

    const { register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        getValues,
        setValue,} = useForm({
        defaultValues: {...initialData,createdAt: new Date().toISOString(), }
    });
    watch('createdAt');
    const isPermissions = usePermission([EUserRoles.ADMIN]);

    const onSubmit = (data: IAddExpenseForm) => {
        const {regionId,...payload} = data;
        if(isPermissions && regionId){
            dispatch(addExpenses({...payload,regionId:regionId?.map(el=>Number(el.id))}));
        }
        if(!isPermissions && user?.regionId){
            dispatch(addExpenses({...payload,regionId:[user?.regionId]}));
        }
    };

    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography sx={{
                display: 'flex',
                justifyContent: 'center'
            }} variant={'h6'}>Добавить расход</Typography>
            <PaymentSelect register={register} errors={errors} name={'paymentId'}/>
            <TextField
                margin="normal"
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.commission}
                helperText={errors.commission ? errors.commission.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Комиссия"
                id="commission"
                {...register('commission',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите комиссию'},
                    })}
            />
            <PermissionHOC roles={[EUserRoles.ADMIN]}>
                <Controller
                    control={control}
                    name={'regionId'}
                    render={({ field: { onChange } }) => (
                        <AutocompleteField
                            multiple={true}
                            onChangeRender={onChange}
                            key={'regionId'}
                            targetValue={'id'}
                            label="Регионы"
                            sx={{mt: '15px', width: '100%'}}
                            fetchData={() => dispatch(getRegions())}
                            dataOptions={data}
                            displayValue1="name"
                            inputProps={{
                                ...register('regionId',
                                    {
                                        required: {value: true, message: 'выберите регион'},
                                    }),
                                error: !!errors.regionId,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore:next-line
                                helperText:  errors.regionId ? errors.regionId?.message : null

                            }}
                        />)}
                />
            </PermissionHOC>
            <TextField
                margin="normal"
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                required={true}
                maxRows={3}
                size='small'
                label="Комментарий"
                autoComplete="comment"
                id="comment"
                {...register('comment',{
                    required: {value: true, message: 'введите комментарий'},
                })}
            />
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value ?? '')}
                value={getValues('createdAt')}
                sx={{
                    margin: '5px 0'
                }}
            />
            <TextField
                margin="normal"
                error={!!errors.transactionId}
                helperText={errors.transactionId ? errors.transactionId.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="ID транзакции"
                autoComplete="transactionId"
                id="transactionId"
                {...register('transactionId',{
                    minLength: {value: 2, message: 'минимальная длинна 2 символа'},
                })}
            />

            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Добавить
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
