import {createSlice, Draft} from '@reduxjs/toolkit';
import {ModalTypes} from '../../components/modals/modalTypes';


export interface IModal {
    type:  keyof typeof ModalTypes | null;
    data: any;
}

const initialState: IModal = {
    type: null,
    data: {}
};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state: Draft<IModal>, { payload }) => {
            state.type = payload.type;
            state.data = payload.data;
        },
        closeModal: (state: Draft<IModal>) => {
            state.type = null;
            state.data = {};
        }
    }
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
