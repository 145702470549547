import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import {Provider} from 'react-redux';
import {persistor, store} from './store';
import Modal from './components/modals/Modal';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import { SnackbarCloseButton } from './components/SnackBar/SnackBar';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <SnackbarProvider
                        action={
                            snackbarKey =><SnackbarCloseButton snackbarKey={snackbarKey}
                            />}
                        autoHideDuration={1500}
                        preventDuplicate
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}>
                        <App />
                    </SnackbarProvider>
                    <Modal/>
                </Router>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
