import {EUserRoles} from '../../enums/roles';
import {useAppSelector} from '../../store/hooks';
import {IAuth} from '../../store/auth/reducer';

export interface Props {
    roles: EUserRoles[];
    children: JSX.Element;
}

export const PermissionHOC = ({ roles, children }: Props) => {
    const { user } = useAppSelector<IAuth>(state => state.auth);
    return (user?.role && roles.includes(user?.role)) ? children : null;
};

export const usePermission = ( roles : EUserRoles[]) => {
    const { user } = useAppSelector<IAuth>(state => state.auth);
    return !!(user?.role && roles.includes(user?.role));
};
