import React from 'react';
import { LocalizationProvider, MobileDatePicker} from '@mui/lab';
import {
    Stack,
    TextField, Theme
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ru } from 'date-fns/locale';
import {SxProps} from '@mui/system';

interface DatePickerProps {
    onChangeHandler(date:string | null): void;
    value: string | null;
    sx?: SxProps<Theme>;
    disabled?: boolean;
}

function DateSelect ({onChangeHandler, value,sx,disabled=false}: DatePickerProps) {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
            <Stack spacing={1}>
                <MobileDatePicker
                    disabled={disabled}
                    toolbarTitle={'Выберите дату'}
                    value={value}
                    onChange={onChangeHandler}
                    disableFuture={true}
                    label="Дата"
                    renderInput={(params) => <TextField
                        {...params}
                        sx={{...sx}}
                        size="small"
                    />}
                />

            </Stack>
        </LocalizationProvider>
    );
}
export default DateSelect;
