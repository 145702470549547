import React, { useEffect } from 'react';
import { Avatar, Box, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getRegions } from '../../../store/regions/thunk';
import { showSnackbar } from '../../../store/snackbar/reducer';
import { createManager } from '../../../store/manager/thunk';
import { RegionSelect } from '../../RegionSelect/RegionSelect';
import { PermissionHOC, usePermission } from '../../Permission';
import { EUserRoles } from '../../../enums/roles';

export interface IAddManagerForm {
    email: string | null,
    nickName: string | null,
    firstName: string | null,
    lastName: string | null,
    boss: string | null,
    rate: number | null,
    phone: string | null,
    telegram: string | null,
    regionId: number | null,
    description: string | null,
    password: string | null,
    confirmPassword: string | null,
}

const initialData: IAddManagerForm = {
    email: null,
    nickName: null,
    firstName: null,
    lastName: null,
    phone: '',
    boss: null,
    rate: 0,
    telegram: null,
    regionId: null,
    description: null,
    password: null,
    confirmPassword: null
};

interface AddManagerFormProps {
    onCancel(): void
}

export const AddManagerForm: React.FC<AddManagerFormProps> = ({onCancel}) =>{
    const dispatch = useAppDispatch();
    const {isLoading} = useAppSelector(state => state.manager);
    const {data: regions} = useAppSelector(state => state.regions);
    const {user} = useAppSelector(state => state.auth);
    const isSenior = usePermission([EUserRoles.SENIOR]);

    useEffect(()=>{
        if(regions.length===0){
            dispatch(getRegions());
        }
    },[]);

    const { register, handleSubmit, formState: { errors } , watch } = useForm({
        defaultValues: {...initialData,password: null,confirmPassword: null }
    });

    const onSubmit = (data: IAddManagerForm) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { confirmPassword, password, regionId,...sendData} = data;
        if(!regionId && !isSenior){
            dispatch(showSnackbar('Укажите регион менеджера', 'error'));
        } else {
            if(isSenior && user?.regionId){
                dispatch(createManager({...sendData, password, regionId:user?.regionId}));
            } else {
                dispatch(createManager({...sendData, password, regionId}));
            }
        }
    };


    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <PermContactCalendarIcon />
                </Avatar>
            </Box>
            <TextField
                margin="normal"
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : null}
                id="email"
                label="E-mail"
                size='small'
                sx={{mt: '5px', width: '100%'}}
                {...register('email', {
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'некорректный адрес ел. почты'
                    },
                    required: {value: true, message: 'введите логин'},
                })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.nickName}
                helperText={errors.nickName ? errors.nickName.message : null}
                required
                fullWidth
                size='small'
                label="Ник"
                autoComplete="nickName"
                id="nickName"
                {...register('nickName',
                    {
                        required: {value: true, message: 'введите ник'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : null}
                required
                fullWidth
                size='small'
                label="Имя"
                autoComplete="firstName"
                id="firstName"
                {...register('firstName',
                    {
                        required: {value: true, message: 'введите имя'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                sx={{mt: '5px', width: '100%'}}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : null}
                required
                fullWidth
                size='small'
                label="Фамилия"
                autoComplete="lastName"
                id="lastName"
                {...register('lastName',
                    {
                        required: {value: true, message: 'введите фамилию'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                sx={{mt: '5px', width: '100%'}}
                error={!!errors.boss}
                helperText={errors.boss ? errors.boss.message : null}
                required
                fullWidth
                size='small'
                label="Старший"
                autoComplete="boss"
                id="boss"
                {...register('boss',
                    {
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                sx={{mt: '5px', width: '100%'}}
                error={!!errors.rate}
                helperText={errors.rate ? errors.rate.message : null}
                fullWidth
                type="number"
                size='small'
                label="Ставка"
                id="rate"
                {...register('rate',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                    })}
            />
            <PermissionHOC roles={[EUserRoles.ADMIN]}>
                <RegionSelect
                    sx={{mt: '5px', width: '100%'}}
                    register={register}
                    errors={errors}
                    name={'regionId'}
                />
            </PermissionHOC>
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : null}
                // required
                fullWidth
                size='small'
                label="Телефон"
                autoComplete="phone"
                id="phone"
                {...register('phone',
                    {
                        // required: {value: true, message: 'введите телефон'},
                        maxLength: {value: 13, message: 'длинна не больше 13 символов'},
                        minLength: {value: 10, message: 'минимальная длинна 10 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.telegram}
                helperText={errors.telegram ? errors.telegram.message : null}
                fullWidth
                size='small'
                label="Телеграм"
                autoComplete="telegram"
                id="telegram"
                {...register('telegram',
                    {
                        maxLength: {value: 15, message: 'длинна не больше 15 символов'},
                        minLength: {value: 4, message: 'минимальная длинна 4 символа' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                autoComplete="description"
                id="description"
                {...register('description')}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : null}
                fullWidth
                size='small'
                label="Новый пароль"
                type="password"
                id="password"
                autoComplete="password"
                {...register('password',
                    {
                        maxLength: {value: 10, message: 'длинна не больше 10 символов'},
                        minLength: {value: 5, message: 'минимальная длинна 5 символа' },
                        required: { value: true, message: 'поле не должно быть пустым' }
                    })}
            />
            <TextField
                sx={{mt: '5px', width: '100%'}}
                margin="normal"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword.message : null}
                fullWidth
                size='small'
                label="Подтвердите пароль"
                type="password"
                id="confirmPassword"
                autoComplete="password"
                {...register('confirmPassword',
                    {
                        validate: (value)=> value ===watch('password') || 'Пароли не совпадают',
                        required: { value: true, message: 'поле не должно быть пустым' }
                    })}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    sx={{flexBasis: '45%'}}
                    variant="outlined">
                    Добавить
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
