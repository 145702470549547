import {createSlice, Draft} from '@reduxjs/toolkit';

export interface IPayments {
    id: number;
    name: string;
}

export interface IPaymentsState {
    data: IPayments[];
    isLoading: boolean,
    error: string | null;
}

const initialState: IPaymentsState = {
    data: [],
    isLoading: false,
    error: null,
};

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers:{
        setLoadingPayments: (state: Draft<IPaymentsState>, { payload }) => {
            state.isLoading = payload;
        },
        setPayments: (state: Draft<IPaymentsState>, { payload }) => {
            state.data = payload;
        },
        setPaymentsError: (state: Draft<IPaymentsState>, { payload }) => {
            state.error = payload;
        },
    }
});

export const {
    setLoadingPayments,
    setPayments,
    setPaymentsError,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
