import React from 'react';
import classes from './../modal.module.scss';
import { Box, Modal } from '@mui/material';
import { useAppDispatch } from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';
import { AddManagerForm } from './AddManagerForm';

function AddManager () {
    const dispatch = useAppDispatch();
    const onCloseModal = () => dispatch(closeModal());

    return (
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <AddManagerForm onCancel={onCloseModal} />
            </Box>
        </Modal>
    );
}

export default AddManager;
