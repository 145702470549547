import {createSlice, Draft} from '@reduxjs/toolkit';
import {IUser} from '../../interfaces/user.interface';

export interface IAuth {
    isAuth: boolean;
    accessToken: string;
    user: IUser | null;
    isLoading: boolean;
}

const initialState: IAuth = {
    accessToken: '',
    isAuth: false,
    user: null,
    isLoading: false
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state: Draft<IAuth>, {payload}) => {
            state.isAuth = payload.isAuth;
            state.accessToken= payload.accessToken;
            state.user = payload.user;
        },
        setLoading: (state, {payload}) => {
            state.isLoading = payload.isLoading;
        },
        profileUpdate: (state: Draft<IAuth>, {payload}) =>  {
            state.user = payload;
        }
    }
});

export const { setUser, setLoading , profileUpdate } = authSlice.actions;

export default authSlice.reducer;
