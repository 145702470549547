import {AppDispatch, store} from '../index';
import { IExpense, setExpenses, setLoadingExpenses } from './expenses-reducer';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../config/axios';
import { EXPENSES_CREATE, EXPENSES_LIST, EXPENSES_REMOVE, EXPENSES_UPDATE } from '../../constants/api-endpoints';
import {showSnackbar} from '../snackbar/reducer';
import {errorMapper} from '../../utils/errorMapper';
import {ErrorMessages} from '../../constants/errors';
import { IAddExpenseForm } from '../../components/modals/AddExpense/AddExpenseForm';
import {closeModal} from '../modal/reducer';

export const getExpenses = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse =
            await axiosInstance.get(EXPENSES_LIST, {params});
        dispatch(setExpenses(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingExpenses(false));
    }
};

export const addExpenses = (newExpense:IAddExpenseForm) => async (dispatch: AppDispatch)=>{
    try{
        const {modal: {data: {onConfirm}}} = store.getState();
        await axiosInstance.post(EXPENSES_CREATE, newExpense);
        dispatch(closeModal());
        if(onConfirm){onConfirm();}
        dispatch(showSnackbar('Расход добавлен успешно', 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.CREATE_DATA,e), 'error'));
    }
};

export const updateExpenses = (updatedExpense:IExpense) => async (dispatch: AppDispatch)=>{
    try{
        await axiosInstance.patch(`${EXPENSES_UPDATE}${updatedExpense.id}`, updatedExpense);
        dispatch(getExpenses());
        dispatch(closeModal());
        dispatch(showSnackbar('Расход обновлен успешно', 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.UPDATE_DATA,e), 'error'));
    }
};

export const removeExpenses = (id:number) => async (dispatch: AppDispatch)=>{
    try{
        const {modal: {data: {updateHandler}}} = store.getState();
        await axiosInstance.delete(EXPENSES_REMOVE+'/'+id);
        dispatch(closeModal());
        if(updateHandler){updateHandler();}
        dispatch(showSnackbar('Расход удален успешно', 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.REMOVE_DATA,e), 'error'));
    }
};


