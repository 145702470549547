export const BASE_URL = process.env.REACT_APP_BACK_HOST ?? 'http://localhost:3000/api';
// export const BASE_URL = 'https://crm.crthamam.xyz/api';
// export const BASE_URL = 'http://178.157.91.100/api';
// export const BASE_URL = process.env.NODE_ENV==='production' ?
//     'https://crm.jahdajdk-crm.com/api'
//     : 'https://safe-tor-82394.herokuapp.com/api';
export const MONOBANK_GET_EXCHANGE = 'https://api.monobank.ua/bank/currency';
export const LOGIN = '/auth/login';
export const MANAGERS_LIST = 'user/managers';
export const INTERMEDIATE_LIST = 'user/intermediate/';
export const USER_LOGS = 'user/logging/';
export const MANAGER_CREATE = 'user/create';
export const MANAGER_UPDATE = 'user/update/';
export const MANAGER_REMOVE = 'user/remove';
export const CLIENTS_LIST = 'client';
export const CLIENTS_LIGHT_LIST = 'client/light';
export const MANAGERS_LIGHT_LIST = 'user/light';
export const CLIENT_MANAGERS_LIGHT_LIST = 'client/';
export const CLIENTS_LOG_LIST = 'client/logging/';
export const CLIENT_CREATE = 'client/create';
export const CLIENT_REMOVE = 'client/remove';
export const CLIENT_EDIT = 'client/update/';
export const TRANSACTION_CREATE = 'transaction';
export const TRANSACTION_CLIENT_LIST = 'client/transactions/';
export const INPUT_TRANSACTION = 'transaction/input';
export const TRANSACTION_REMOVE = 'transaction/remove/';
export const TRANSACTION_UPDATE = 'transaction/update/';
export const OUTPUT_TRANSACTION = 'transaction/output';
export const REGIONS_LIST = 'region/';
export const PAYMENTS_LIST = 'payments/';
export const EXPENSES_LIST = 'expenses/';
export const EXPENSES_CREATE = 'expenses/create';
export const EXPENSES_UPDATE = 'expenses/update/';
export const EXPENSES_REMOVE = 'expenses/remove';
export const TRANSFER_CREATE = 'transfer/create';
export const TRANSFER_REMOVE = 'transfer/remove/';
export const TRANSFER_UPDATE = 'transfer/update/';
export const TRANSFER_LIST = 'transfer/';
export const GET_SALARY = '/user/salary';
export const ADD_SALARY = '/salary';
export const ADD_SALARY_ALL = '/salary/all';
export const ADD_PENCIL = '/pencil/create/';
export const GET_PENCIL = '/pencil/';
export const GET_PENCIL_LOGS = '/pencil/logs/';
export const REMOVE_PENCIL_LOGS = '/pencil/remove/';
export const UPDATE_PENCIL_LOGS = '/pencil/update/';
export const GET_REGION_SALARY = '/salary/regions';
export const GET_SALARY_LOGS = '/salary/logs';
export const REMOVE_SALARY_LOGS = '/salary/remove/';
export const UPDATE_SALARY_LOGS = '/salary/update/';
//Reports
export const REPORTS_STATISTIC = '/reports/statistic';
export const REPORTS_WALLETS = '/reports/wallets';
export const REPORTS_WALLETS_DETAILS = '/reports/wallets/details/';
