import {createSlice, Draft} from '@reduxjs/toolkit';
import {IClientLight, IClientTable } from '../../interfaces/client.interface';

export interface IClientsListState {
  clients: IClientTable[],
  clientLight: IClientLight[],
  count: number,
  total: {
      totalInput: number;
      totalOutput: number;
  },
  isLoading: boolean,
  error: string | null
}

const initialState: IClientsListState = {
    clients: [],
    clientLight: [],
    count: 0,
    total: {
        totalInput: 0,
        totalOutput: 0
    },
    isLoading: false,
    error: null,
};

export const clientListSlice = createSlice({
    name: 'clientList',
    initialState,
    reducers: {
        updateClientAtTable: (state: Draft<IClientsListState>, {payload}) => {
            state.clients = state.clients.map(item=>{
                return item.id!==payload.id ?  item : {...item,...payload};
            });
        },
        clearClients: (state: Draft<IClientsListState>) => {
            state.clients = [];
            state.count = 0;
            state.total = {
                totalInput: 0,
                totalOutput: 0
            };
        },
        setClients: (state: Draft<IClientsListState>, {payload}) => {
            state.clients = payload.clients;
            state.count = payload.count;
            state.total = payload.total;
        },
        setClientsLight: (state: Draft<IClientsListState>, {payload}) => {
            state.clientLight = payload;
        },
        setLoadingClients: (state: Draft<IClientsListState>, {payload}) => {
            state.isLoading = payload;
        },
        setClientsError: (state: Draft<IClientsListState>,  {payload}) => {
            state.error = payload;
        },
    }
});

export const {
    setClients,
    setLoadingClients,
    setClientsError,
    updateClientAtTable,
    setClientsLight,
    clearClients
} = clientListSlice.actions;

export default clientListSlice.reducer;
