import {createSlice, Draft} from '@reduxjs/toolkit';

export interface IManagerLight {
    nickName: string;
    id: number;
}

export interface IManagerLightState {
    data: IManagerLight[];
    isLoading: boolean,
}

const initialState: IManagerLightState = {
    data: [],
    isLoading: false,
};

export const managerLightSlice = createSlice({
    name: 'managerLight',
    initialState,
    reducers: {
        setManagerLight: (state: Draft<IManagerLightState>, {payload})=>{
            state.data = payload;
        },
        setLoadingManagerLight: (state:Draft<IManagerLightState>,{payload} )=>{
            state.isLoading = payload;
        },
    }
});

export const {
    setManagerLight,
    setLoadingManagerLight
} = managerLightSlice.actions;

export default managerLightSlice.reducer;
