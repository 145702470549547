import { Icon, Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { routersPath } from '../router/routes-path';
import { useAppSelector } from '../store/hooks';
import { EUserRoles } from '../enums/roles';

const NotFound: React.FC = () => {
    const {user} = useAppSelector(state => state.auth);
    const getRedirect = () =>{
        switch (user?.role){
        case EUserRoles.ADMIN:{
            return routersPath.ADMIN_DASHBOARD_CLIENTS;
            break;
        }
        case EUserRoles.MANAGER:{
            return routersPath.MANAGER_DASHBOARD_HOME;
            break;
        }
        default:{
            return routersPath.LOGIN_PAGE;
            break;
        }
        }
    };
    return <Box sx={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        mt:20,
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexWrap: 'wrap',

        }}>
            <Icon color={'warning'} sx={{
                width: '80px',
                height: '80px',
                mr:3,
            }}>
                <ErrorIcon sx={{
                    width: '80px',
                    height: '80px',
                }}/>
            </Icon>
            <Typography variant={'h4'} sx={{
                textAlign:'center',
            }}>
                Страница не найдена
            </Typography>
        </Box>
        <Box>
            <Typography variant={'h6'} sx={{
                mt:5,
                textAlign:'center',
            }}>
                <a href={getRedirect()} style={{
                    textDecoration: 'none',
                }}>
                    Перейти на главную страницу
                </a>
            </Typography>
        </Box>

    </Box>;
};

export default NotFound;
