import {createSlice, Draft} from '@reduxjs/toolkit';

export interface IPencilItem {
    id?: number,
    formula: number,
    totalSalary: number,
    value: number,
    totalInput: number,
    totalOutput: number,
    differenceInputOutput: number,
    manager:{
        firstName: string,
        id: number,
        lastName: string,
        nickName: string,
    },
    region: {
        id: number,
        name: string,
    },
    regionId: number,
}

export interface IPencilState {
    pencil: {
        count: number,
        data: IPencilItem[],
        totalMinusFormula: number,
        totalPlusFormula: number,
        totalValue: number,
        isLoading: boolean;
    },
    logs: {
        data: any[],
        count: number;
        isLoading: boolean;
        totalFormula: number;
        totalValue: number;
    },
    error: string | null;
}

const initialState: IPencilState = {
    pencil: {
        count: 0,
        data: [],
        totalMinusFormula: 0,
        totalPlusFormula: 0,
        totalValue: 0,
        isLoading: false,
    },
    logs: {
        data: [],
        count: 0,
        isLoading: false,
        totalFormula: 0,
        totalValue: 0,
    },
    error: null,
};

export const pencilSlice = createSlice({
    name: 'pencil',
    initialState,
    reducers: {
        setLoadingPencil: (state: Draft<IPencilState>,{payload}) =>{
            state.pencil.isLoading = payload;
        },
        setPencil: (state: Draft<IPencilState>,{payload}) =>{
            state.pencil.data = payload.data;
            state.pencil.count = payload.count;
            state.pencil.totalMinusFormula = payload.totalMinusFormula;
            state.pencil.totalPlusFormula = payload.totalPlusFormula;
            state.pencil.totalValue = payload.totalValue;
        },
        setPencilError: (state: Draft<IPencilState>,{payload}) =>{
            state.error = payload;
        },
        setLoadingPencilLogs: (state: Draft<IPencilState>,{payload}) =>{
            state.logs.isLoading = payload;
        },
        setPencilLogs: (state: Draft<IPencilState>,{payload}) =>{
            state.logs.data = payload.data;
            state.logs.count = payload.count;
            state.logs.totalFormula = payload.totalFormula;
            state.logs.totalValue = payload.totalValue;
        },
        setPencilLogsError: (state: Draft<IPencilState>,{payload}) =>{
            state.error = payload;
        },
    }
});

export const {
    setLoadingPencil,
    setPencil,
    setPencilError,
    setLoadingPencilLogs,
    setPencilLogs,
    setPencilLogsError
} = pencilSlice.actions;

export default pencilSlice.reducer;
