import React  from 'react';
import { Box, Modal } from '@mui/material';
import classes from '../modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';
import { UpdateExpenseForm } from './UpdateExpenseForm';
import { updateExpenses } from '../../../store/expenses/thunk';
import { IExpense } from '../../../store/expenses/expenses-reducer';

export const UpdateExpense: React.FC = () =>{
    const dispatch = useAppDispatch();
    const { data: {expenses} } = useAppSelector(state => state.modal);

    const onCloseModal = () => dispatch(closeModal());

    const onSubmit = (data: IExpense) => {
        dispatch(updateExpenses(data));
    };

    return (
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <UpdateExpenseForm expensesData={expenses} onCancel={onCloseModal} onSubmit={onSubmit} />
            </Box>
        </Modal>
    );
};
