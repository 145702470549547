import {AppDispatch, store} from '../index';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../config/axios';
import {OUTPUT_TRANSACTION, TRANSACTION_REMOVE, TRANSACTION_UPDATE} from '../../constants/api-endpoints';
import { showSnackbar } from '../snackbar/reducer';
import { errorMapper } from '../../utils/errorMapper';
import { ErrorMessages } from '../../constants/errors';
import { setLoadingOutputTransaction, setOutputTransaction } from './reducer';
import {closeModal} from '../modal/reducer';
import { UpdateTransactionOutputForm } from '../../components/modals/UpdateOutputTransaction';

export const getOutputTransactions = (params?: any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse = await axiosInstance.get(OUTPUT_TRANSACTION, {
            params
        });
        dispatch(setOutputTransaction(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_LIST_FAILED,e), 'error'));
        console.log(e);
    } finally {
        dispatch(setLoadingOutputTransaction(false));
    }
};

export const removeOutputTransaction = (id: number) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination}}, outputTransactions: {data}} = store.getState();
    try{
        dispatch(setLoadingOutputTransaction(true));
        await axiosInstance.delete(TRANSACTION_REMOVE+id);
        if((data.length-1)===0){
            dispatch(getOutputTransactions({
                pageSize:pagination.perPage,page: pagination.page
            },));
        } else {
            dispatch(getOutputTransactions({
                pageSize:pagination.perPage,page: pagination.page+1
            },));
        }
        dispatch(closeModal());
        dispatch(showSnackbar('запись пополнения удалена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_LIST_FAILED,e), 'error'));
        console.log(e);
    } finally {
        dispatch(setLoadingOutputTransaction(false));
    }
};

export const updateOutputTransaction = (data: UpdateTransactionOutputForm) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination}}} = store.getState();
    const {id, ...updateData} = data;
    try{
        await axiosInstance.patch(TRANSACTION_UPDATE+id,updateData);

        dispatch(getOutputTransactions({
            pageSize:pagination.perPage,page: pagination.page+1
        },));
        dispatch(closeModal());
        dispatch(showSnackbar('запись вывода обновлена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_LIST_FAILED,e), 'error'));
        console.log(e);
    }
};
