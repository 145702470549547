import {useForm} from 'react-hook-form';
import {Box, Button, TextField, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import {addTransfer} from '../../../store/transfers/thunk';
import {RegionSelect} from '../../RegionSelect/RegionSelect';
import DateSelect from '../../DateSelect';

export interface IAddTransferForm {
    paymentId: number | null;
    toPaymentId: number | null;
    currency: number | null;
    commission: number | null;
    comment: string | null;
    createdAt: string | null;
}

const initialData: IAddTransferForm = {
    paymentId: null,
    toPaymentId: null,
    currency: null,
    commission: null,
    comment: null,
    createdAt: null,
};

interface AddTransferFormProps {
    onCancel(): void
}

export const AddTransferForm: React.FC<AddTransferFormProps> =({onCancel})=>{
    const dispatch = useAppDispatch();
    const {isLoading} = useAppSelector(state => state.transfers);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        setValue,} = useForm({
        defaultValues: {...initialData, createdAt: new Date().toISOString(), }
    });

    const onSubmit = (data: IAddTransferForm) => {
        dispatch(addTransfer(data));
    };
    watch('createdAt');
    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center'
            }} variant={'h6'}>Добавить перевод между кошельками</Typography>
            <PaymentSelect 
                label='Платежка с'
                register={register} 
                errors={errors} 
                name={'paymentId'}/>
               <PaymentSelect 
                label='Платежка на'
                register={register} 
                errors={errors} 
                name={'toPaymentId'}/>
            <TextField
                margin="normal"
                error={!!errors.currency}
                helperText={errors.currency ? errors.currency.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="currency"
                {...register('currency',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    }
                )}
            />
            <TextField
                margin="normal"
                error={!!errors.commission}
                helperText={errors.commission ? errors.commission.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Комиссия"
                id="commission"
                {...register('commission',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите комиссию'},
                    })}
            />
            <RegionSelect
                sx={{mt: '5px', width: '100%'}}
                register={register}
                errors={errors}
                name={'regionId'}
            />
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value ?? '')}
                value={getValues('createdAt')}
                sx={{
                    margin: '5px 0'
                }}
            />
            <TextField
                margin="normal"
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                required
                maxRows={3}
                size='small'
                label="Комментарий"
                autoComplete="comment"
                id="comment"
                {...register('comment',{
                    required: {value: true, message: 'введите комментарий'},
                })}
            />

            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Добавить
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
