import React, {useEffect, useState} from 'react';
import {Avatar, Box, Button, TextField} from '@mui/material';
import {useForm} from 'react-hook-form';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {createClient} from '../../../store/client/thunk';
import AutocompleteField from '../../AutocompleteField';
import {getManagerLight} from '../../../store/managerLight/thunks';
import {PermissionHOC, usePermission} from '../../Permission';
import {EUserRoles} from '../../../enums/roles';

export interface IAddClientForm{
    nickName: string | null,
    phone: string | null,
    telegram: string | null,
    description: string | null,
    managersId?: number[] | null,
    referral?: number | null,
}

const initialData: IAddClientForm = {
    nickName: null,
    phone: '',
    telegram: null,
    description: null,
    managersId: null,
    referral: null,
};

interface AddClientFormProps {
  onCancel(): void
}

export const AddClientForm: React.FC<AddClientFormProps> = ({onCancel}) =>{
    const dispatch = useAppDispatch();
    const {data:managers} = useAppSelector(state => state.managerLight);
    const {user} = useAppSelector(state => state.auth);
    const {isLoading} = useAppSelector(state => state.client);
    const [managerValue, setManagerValue] = useState<number[] | null>(null);
    const { register, handleSubmit, formState: { errors }, setValue} = useForm({
        defaultValues: {...initialData }
    });
    const isPermissions = usePermission([EUserRoles.ADMIN,EUserRoles.SENIOR]);
    useEffect(()=>{
        setValue('managersId',managerValue);
    },[managerValue]);

    const onSubmit = (data: IAddClientForm) => {
        const {managersId,...payload} = data;
        dispatch(createClient(isPermissions ?
            {managersId,...payload} :
            user?.id ? {
                managersId:[user?.id],
                ...payload} :
                payload));
    };

    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <PermContactCalendarIcon />
                </Avatar>
            </Box>

            <TextField
                sx={{fontSize:'5px'}}
                margin="normal"
                error={!!errors.nickName}
                helperText={errors.nickName ? errors.nickName.message : null}
                required
                fullWidth
                size='small'
                label="Ник"
                autoComplete="nickName"
                id="nickName"
                {...register('nickName',
                    {
                        required: {value: true, message: 'введите ник'},
                        maxLength: {value: 40, message: 'длинна не больше 40 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : null}
                // required
                fullWidth
                size='small'
                label="Телефон"
                autoComplete="phone"
                id="phone"
                {...register('phone',
                    {
                        // required: {value: true, message: 'введите телефон'},
                        maxLength: {value: 13, message: 'длинна не больше 13 символов'},
                        minLength: {value: 10, message: 'минимальная длинна 10 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.referral}
                helperText={errors.referral ? errors.referral.message : null}
                fullWidth
                size='small'
                label="Реферал"
                autoComplete="referral"
                id="referral"
                {...register('referral',
                    {
                        maxLength: {value: 40, message: 'длинна не больше 40 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <PermissionHOC roles={[EUserRoles.ADMIN,EUserRoles.SENIOR]}>
                <AutocompleteField
                    multiple={true}
                    key={'managersId'}
                    targetValue={'id'}
                    label="Менеджеры"
                    sx={{mt: '15px', width: '100%'}}
                    selectedValue={managerValue}
                    setSelectedValue={setManagerValue}
                    fetchData={() => dispatch(getManagerLight())}
                    dataOptions={managers}
                    displayValue1="nickName"
                />
            </PermissionHOC>
            <TextField
                margin="normal"
                error={!!errors.telegram}
                helperText={errors.telegram ? errors.telegram.message : null}
                fullWidth
                size='small'
                label="Телеграм"
                autoComplete="telegram"
                id="telegram"
                {...register('telegram',
                    {
                        maxLength: {value: 30, message: 'длинна не больше 30 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                autoComplete="description"
                id="description"
                {...register('description')}
            />

            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Добавить
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
