import {createSlice, Draft} from '@reduxjs/toolkit';
import { IClient } from '../../interfaces/client.interface';
import { ILogging } from '../../interfaces/logging.interface';

export interface IClientLogging{
    id: number,
    action: string,
    telegram: string | null,
    phone: string | null,
    createdAt: string,
    firstName: string | null,
    lastName: string | null,
    description: string | null,
    user: {
        id: number,
        lastName:string,
        firstName: string,
        email: string,
    }
}

export interface IClientState {
  data: IClient | null,
  isLoading: boolean,
  error: string | null
  logging: ILogging<IClientLogging>,
}

const initialState: IClientState = {
    data: null,
    isLoading: false,
    error: null,
    logging:{
        logs: [],
        count: 0,
        isLoading: false
    },
};

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClient: (state: Draft<IClientState>, { payload }) => {
            state.data = {...state.data, ...payload};
        },
        setLoadingClient: (state: Draft<IClientState>, { payload }) => {
            state.isLoading = payload;
        },
        setClientError: (state: Draft<IClientState>, { payload }) => {
            state.error = payload;
        },
        setClientLogging: (state: Draft<IClientState>, { payload }) => {
            state.logging.logs = payload.logging;
            state.logging.count = payload.count;
        },
        setClientLoggingLoading: (state: Draft<IClientState>,{payload} ) => {
            state.logging.isLoading = payload;
        },
        setClientBalance: (state: Draft<IClientState>, { payload }) => {
            state.data = {...state.data, balance: payload} as IClient;
        },
    }
});

export const {
    setClient,
    setLoadingClient,
    setClientError,
    setClientLogging,
    setClientBalance,
    setClientLoggingLoading
} = clientSlice.actions;

export default clientSlice.reducer;
