import React from 'react';
import { IRoute } from '../../interfaces/router/route.interface';
import { routersPath } from '../routes-path';
import { EUserRoles } from '../../enums/roles';

const ClientsPage = React.lazy(() => import('../../pages/Clients'));
const ClientPage = React.lazy(
  () => import('../../pages/manager/ManagerClient'),
);
const PencilPage = React.lazy(() => import('../../pages/admin/PencilPage'));
const InputPage = React.lazy(() => import('../../pages/admin/InputPage'));
const OutputPage = React.lazy(() => import('../../pages/admin/OutputPage'));

const managerRoute = {
  exact: true,
  roles: [EUserRoles.MANAGER],
};

export const managerRoutes: IRoute[] = [
  {
    ...managerRoute,
    path: routersPath.MANAGER_DASHBOARD_HOME,
    component: ClientsPage,
    title: 'Клиенты',
  },
  {
    ...managerRoute,
    path: routersPath.ADMIN_DASHBOARD_INPUT,
    component: InputPage,
    title: 'Пополнения',
  },
  {
    ...managerRoute,
    path: routersPath.ADMIN_DASHBOARD_OUTPUT,
    component: OutputPage,
    title: 'Выводы',
  },
  {
    ...managerRoute,
    path: routersPath.MANAGER_CLIENT_PROFILE,
    component: ClientPage,
    title: 'Детали клиента',
  },
  {
    ...managerRoute,
    path: routersPath.MANAGER_DASHBOARD_PENCIL,
    component: PencilPage,
    title: 'Карандаш',
  },
];
