import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';
import { Box, Modal } from '@mui/material';
import classes from '../modal.module.scss';
import { TransactionInputForm } from './TransactionInputForm';
import { getClient } from '../../../store/client/thunk';
import Loader from '../../Loader';

export const TransactionInput = () =>{
    const dispatch = useAppDispatch();
    const { data: modal } = useAppSelector(state => state.modal);
    const { data: client, isLoading } = useAppSelector(state => state.client);

    useEffect(()=>{
        if(modal?.id){
            dispatch(getClient({id: modal.id}));
        }
    },[]);

    const onCloseModal = () => dispatch(closeModal());
    return (
        <>
            <Modal
                sx={{
                    position:'absolute',
                    overflow:'auto',
                    height:'100%',
                }}
                open={true}
                className={classes.container}
            >
                <Box
                    className={classes.wrapper}
                    sx={{
                        position: 'inherit',
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {(client && !isLoading) ? <TransactionInputForm onCancel={onCloseModal} /> : <Loader/>}
                </Box>
            </Modal>
        </>
    );
};
