import {AppDispatch, store} from '../index';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../config/axios';
import {INPUT_TRANSACTION, TRANSACTION_REMOVE, TRANSACTION_UPDATE} from '../../constants/api-endpoints';
import { showSnackbar } from '../snackbar/reducer';
import { errorMapper } from '../../utils/errorMapper';
import { ErrorMessages } from '../../constants/errors';
import { setInputTransaction, setLoadingInputTransaction } from './reducer';
import {closeModal} from '../modal/reducer';
import {UpdateTransactionInputForm} from '../../components/modals/UpdateInputTransaction';

export const getInputTransactions = (params?: any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse = await axiosInstance.get(INPUT_TRANSACTION, {
            params
        });
        dispatch(setInputTransaction(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_LIST_FAILED,e), 'error'));
        console.log(e);
    } finally {
        dispatch(setLoadingInputTransaction(false));
    }
};

export const removeInputTransaction = (id: number) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination}}, inputTransactions: {data}} = store.getState();
    try{
        dispatch(setLoadingInputTransaction(true));
        await axiosInstance.delete(TRANSACTION_REMOVE+id);
        if((data.length-1)===0){
            dispatch(getInputTransactions({
                pageSize:pagination.perPage,page: pagination.page
            },));
        } else {
            dispatch(getInputTransactions({
                pageSize:pagination.perPage,page: pagination.page+1
            },));
        }
        dispatch(closeModal());
        dispatch(showSnackbar('запись пополнения удалена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_LIST_FAILED,e), 'error'));
        console.log(e);
    } finally {
        dispatch(setLoadingInputTransaction(false));
    }
};

export const updateInputTransaction = (data: UpdateTransactionInputForm) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination}}} = store.getState();
    const {id, ...updateData} = data;
    try{
        await axiosInstance.patch(TRANSACTION_UPDATE+id,updateData);

        dispatch(getInputTransactions({
            pageSize:pagination.perPage,page: pagination.page+1
        },));
        dispatch(closeModal());
        dispatch(showSnackbar('запись пополнения удалена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.TRANSACTION_LIST_FAILED,e), 'error'));
        console.log(e);
    }
};

