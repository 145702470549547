import {EUserRoles} from '../enums/roles';

export enum routersPath {
    LOGIN_PAGE = '/',
    ADMIN_DASHBOARD_MANAGERS = '/admin/managers',
    ADMIN_DASHBOARD_SENIORS = '/admin/seniors',
    ADMIN_DASHBOARD_PENCIL = '/admin/pencil',
    ADMIN_DASHBOARD_CLIENTS = '/admin/clients',
    ADMIN_DASHBOARD_INPUT = '/admin/input',
    ADMIN_DASHBOARD_OUTPUT = '/admin/output',
    ADMIN_DASHBOARD_EXPENSES = '/admin/expenses',
    ADMIN_DASHBOARD_TRANSFERS = '/admin/transfers',
    ADMIN_DASHBOARD_REPORTS = '/admin/reports',
    ADMIN_DASHBOARD_SALARY = '/admin/salary',
    ADMIN_MANAGER_PROFILE = '/admin/managers/:managerId',
    ADMIN_CLIENT_PROFILE = '/admin/clients/:clientId',
    MANAGER_DASHBOARD_HOME = '/manager/clients',
    MANAGER_CLIENT_PROFILE = '/manager/clients/:clientId',
    MANAGER_DASHBOARD_PENCIL = '/manager/pencil',
    ERROR_PAGE = '/error-page',
}

export function getDefaultPathByRole (role: number): string  {
    switch (role){
    case EUserRoles.ADMIN:
        return routersPath.ADMIN_DASHBOARD_CLIENTS;
    case EUserRoles.SENIOR:
        return routersPath.ADMIN_DASHBOARD_CLIENTS;
    case EUserRoles.MANAGER:
        return routersPath.MANAGER_DASHBOARD_HOME;
    default:
        return routersPath.LOGIN_PAGE;
    }
}
