import {AppDispatch} from '../index';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../config/axios';
import { REGIONS_LIST} from '../../constants/api-endpoints';
import {showSnackbar} from '../snackbar/reducer';
import {errorMapper} from '../../utils/errorMapper';
import {ErrorMessages} from '../../constants/errors';
import { setLoadingRegions, setRegions } from './regions-reducer';

export const getRegions = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        dispatch(setLoadingRegions(true));
        const {data}: AxiosResponse =
            await axiosInstance.get(REGIONS_LIST, {params});
        dispatch(setRegions(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingRegions(false));
    }
};
