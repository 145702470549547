import {AppDispatch, store} from '../index';
import {
    setLoadingSalary,
    setLoadingSalaryLogs,
    setLoadingSalaryRegions,
    setSalary,
    setSalaryLogs,
    setSalaryRegions
} from './salary-reducer';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../config/axios';
import {
    ADD_SALARY,
    ADD_SALARY_ALL,
    GET_REGION_SALARY,
    GET_SALARY,
    GET_SALARY_LOGS,
    REMOVE_SALARY_LOGS,
    UPDATE_SALARY_LOGS} from '../../constants/api-endpoints';
import {showSnackbar} from '../snackbar/reducer';
import {errorMapper} from '../../utils/errorMapper';
import {ErrorMessages} from '../../constants/errors';
import {closeModal} from '../modal/reducer';
import { Page } from '../../hooks/usePaginationHandlers';

interface IPaySalary {
    exchangeRate: number;
    managerId: number;
    salary: number;
    from: string;
    to: string;
}

export interface ISalaryLogUpdate {
    exchangeRate: number;
    salary: number;
    id: number;
}

export const getSalary = (params?: any) => async (dispatch: AppDispatch)=> {
    try {
        const {data}: AxiosResponse =
            await axiosInstance.get(GET_SALARY, {params});
        dispatch(setSalary(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }finally {
        dispatch(setLoadingSalary(false));
    }
};

export const paySalary = (payload: IPaySalary) => async (dispatch: AppDispatch)=> {
    try{
        const {modal: {data: {onConfirm}}} = store.getState();
        await axiosInstance.post(ADD_SALARY, {...payload});
        dispatch(closeModal());
        if(onConfirm){onConfirm();}
        dispatch(showSnackbar('Выплачено успешно', 'success'));
    }catch (e) {
        dispatch(showSnackbar('Ошибка выплаты зарплаты', 'error'));
    }
};

export const paySalaryForAll = (
    payload: Omit<IPaySalary, 'managerId'> & {pagination?: Page}
) => async (dispatch: AppDispatch)=> {
    try{
        await axiosInstance.post(ADD_SALARY_ALL, {...payload});
        dispatch(closeModal());
        dispatch(getSalary({
            pageSize: payload.pagination?.perPage ? payload.pagination?.perPage : 25,
            page: (payload.pagination?.page || payload.pagination?.page===0) ? payload.pagination?.page+1 : 1,
        }));
        dispatch(showSnackbar('Выплачено всем успешно', 'success'));
    }catch (e) {
        dispatch(showSnackbar('Ошибка выплаты зарплаты', 'error'));
    }
};

export const getSalaryRegions = (params?: any) => async (dispatch: AppDispatch)=> {
    try {
        const {data}: AxiosResponse =
            await axiosInstance.get(GET_REGION_SALARY, {params});
        dispatch(setSalaryRegions(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }finally {
        dispatch(setLoadingSalaryRegions(false));
    }
};

export const getSalaryLogs = (params?: any) => async (dispatch: AppDispatch)=> {
    try {
        const {data}: AxiosResponse =
            await axiosInstance.get(GET_SALARY_LOGS, {params});
        dispatch(setSalaryLogs(data));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }finally {
        dispatch(setLoadingSalaryLogs(false));
    }
};

export const removeSalaryLogs = (id: number) => async (dispatch: AppDispatch)=> {
    const {modal: {data: {pagination,calendar}},salary:{logs:{data}}} = store.getState();
    try {
        await axiosInstance.delete(REMOVE_SALARY_LOGS+id);
        if((data.length-1)===0){
            dispatch(getSalaryLogs({
                pageSize:pagination.perPage,page: pagination.page,calendar
            },));
        } else {
            dispatch(getSalaryLogs({
                pageSize:pagination.perPage,page: pagination.page+1,calendar
            },));
        }
        dispatch(closeModal());
        dispatch(showSnackbar('запись удалена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }
};

export const updateSalaryLogs = ({id,...payload}: ISalaryLogUpdate) => async (dispatch: AppDispatch)=> {
    const {modal: {data: {pagination,calendar}}} = store.getState();
    try {
        await axiosInstance.patch(UPDATE_SALARY_LOGS+id,{...payload});
        dispatch(getSalaryLogs({
            pageSize:pagination.perPage,page: pagination.page,calendar
        },));
        dispatch(closeModal());
        dispatch(showSnackbar('запись обновлена', 'success'));
    } catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }
};
