import {createSlice, Draft} from '@reduxjs/toolkit';

export interface IClientLight {
    id: number;
    nickName: string;
    telegram: string;
}

export interface IClientLightState {
    data: IClientLight[],
    isLoading: boolean,
    error: string | null
}

const initialState: IClientLightState = {
    data: [],
    isLoading: false,
    error: null
};

export const clientLightSlice = createSlice({
    name: 'clientLight',
    initialState,
    reducers: {
        setClientLight: (state: Draft<IClientLightState>, {payload}) => {
            state.data = payload;
        },
        setLoadingClientLight: (state: Draft<IClientLightState>, {payload}) => {
            state.isLoading = payload;
        },
        setClientLightError: (state: Draft<IClientLightState>, {payload}) => {
            state.error = payload;
        },
    }
});

export const {
    setClientLight,
    setLoadingClientLight,
    setClientLightError,
} = clientLightSlice.actions;

export default clientLightSlice.reducer;
