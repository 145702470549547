import {AppDispatch} from '../index';
import { setLoadingManagerLight, setManagerLight } from './managerLight-reducer';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../config/axios';
import {MANAGERS_LIGHT_LIST} from '../../constants/api-endpoints';
import {showSnackbar} from '../snackbar/reducer';
import {errorMapper} from '../../utils/errorMapper';
import {ErrorMessages} from '../../constants/errors';

export const getManagerLight = (params?: any) => async (dispatch: AppDispatch)=>{
    try{
        dispatch(setLoadingManagerLight(true));
        const {data}: AxiosResponse =
            await axiosInstance.get(MANAGERS_LIGHT_LIST, {params});
        dispatch(setManagerLight(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }finally {
        dispatch(setLoadingManagerLight(false));
    }
};
