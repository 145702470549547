import {createSlice, Draft} from '@reduxjs/toolkit';
import { IManager } from '../../interfaces/manager.interface';
import { IManagerLogs } from '../../interfaces/manager-logs.interface';
import { ILogging } from '../../interfaces/logging.interface';

export interface IManagerState {
  data: IManager | null,
  isLoading: boolean,
  error: string | null,
  logging: ILogging<IManagerLogs>,
}

const initialState: IManagerState = {
    data: null,
    isLoading: false,
    error: null,
    logging: {
        logs: [],
        count: 0,
        isLoading: false
    },
};

export const managerSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        setManager: (state: Draft<IManagerState>, {payload}) => {
            state.data = {...state.data, ...payload};
        },
        clearManager: (state: Draft<IManagerState>) => {
            state.data = null;
        },
        setLoadingManager: (state: Draft<IManagerState>, {payload}) => {
            state.isLoading = payload;
        },
        setManagerError: (state: Draft<IManagerState>,  {payload}) => {
            state.error = payload;
        },
        setManagerLogsLoading: (state: Draft<IManagerState>, {payload}) => {
            state.logging.isLoading = payload;
        },
        setManagerLogs: (state: Draft<IManagerState>, {payload}) => {
            state.logging.logs = payload.logging;
            state.logging.count = payload.count;
        }
    }
});

export const {
    setManager,
    setLoadingManager,
    setManagerError,
    setManagerLogs,
    setManagerLogsLoading,
    clearManager
} = managerSlice.actions;

export default managerSlice.reducer;
