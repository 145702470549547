import { IPayments } from '../payments/payments-reducer';
import {createSlice, Draft} from '@reduxjs/toolkit';

export interface ITransfer {
    id: number;
    payment: IPayments;
    paymentId: number;
    toPayment:IPayments;
    commission:number;
    currency: string;
    comment: string;
    createdAt: string;
    updatedAt: string;
    regionId?: number;
    region:{id:number,name:string}
}

export interface ITransferState {
    data: ITransfer[];
    count: number;
    totalSum: number;
    total: number | null;
    isLoading: boolean,
    error: string | null;
}

const initialState : ITransferState = {
    data: [],
    count: 0,
    totalSum: 0,
    total: null,
    isLoading: false,
    error: null,
};

export const transferSlice = createSlice({
    name: 'transfers',
    initialState,
    reducers: {
        getTransfer: (state: Draft<ITransferState>, {payload}) => {
            state.data.find(item => item.id === payload.id);
        },
        setTransfers: (state:Draft<ITransferState>,{payload} )=>{
            state.data = payload.data;
            state.count = payload.count;
            state.total = payload.total;
            state.totalSum = payload.total_sum;
        },
        setLoadingTransfers: (state:Draft<ITransferState>,{payload} )=>{
            state.isLoading = payload;
        },
        setExpensesTransfers: (state: Draft<ITransferState>,  {payload}) => {
            state.error = payload;
        },
    }
});

export const {
    setTransfers,
    setLoadingTransfers,
    setExpensesTransfers,
} = transferSlice.actions;

export default transferSlice.reducer;
