import React, {useState} from 'react';
import {AppBar, IconButton, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ProfileAvatar } from './ProfileAvatar';
import {Sidebar} from './Sidebar';
import {openModal} from '../../store/modal/reducer';
import { useAppDispatch } from '../../store/hooks';

interface IHeader{
   title?: string;
}

export const Header: React.FC<IHeader> = ({title}) =>{
    const dispatch = useAppDispatch();
    const [isOpenDrawer, openDrawer] =useState<boolean>(false);
    const handleDrawerClose = () =>{
        openDrawer(!isOpenDrawer);
    };
    const handleProfileModalOpen = () =>{
        dispatch(openModal({type: 'ProfileModal'}));
    };
    return <>
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerClose}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="span" sx={{flexGrow:1}}>
                    {title}
                </Typography>
                <ProfileAvatar openProfileModal={handleProfileModalOpen}/>
            </Toolbar>
        </AppBar>
        <Sidebar isOpenDrawer={isOpenDrawer} handleDrawerClose={handleDrawerClose}/>
    </>;
};
