export const errorMapper = (message: string,e:any) =>{
    switch (e?.response?.data?.message){
    case 'phone must be unique':
        return 'телефон должен быть уникальным';
    case 'email must be unique':
        return 'email должен быть уникальным';
    default:
        return message;
    }
};
