import React, {useEffect, useState} from 'react';
import { PaymentType } from '../TransactionInput/TransactionInputForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, TextField, Typography} from '@mui/material';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import {ClientSelect} from '../../ClientSelect';
import { createTransaction } from '../../../store/transaction/thunk';
import {ManagerLight} from '../TransactionInputPage/TransactionInputFormPage';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../../config/axios';
import {CLIENT_MANAGERS_LIGHT_LIST} from '../../../constants/api-endpoints';
import AutocompleteField from '../../AutocompleteField';
import DateSelect from '../../DateSelect';

interface ITransactionOutputForm {
  clientId: any,
  numberWallet: string | null,
  payment: string | null,
  commission: number | null,
  paymentId: string | null,
  type: PaymentType,
  revision: number | null,
  comment: string |null,
  managersId: any[] | null,
  createdAt: string | null
}

const initialData: ITransactionOutputForm = {
    clientId: null,
    revision: null,
    numberWallet: null,
    payment: null,
    commission: null,
    paymentId: null,
    type: PaymentType.output,
    comment: null,
    managersId: null,
    createdAt: null
};

interface TransactionOutputFormProps {
  onCancel(): void
}

export const TransactionOutputPageForm: React.FC<TransactionOutputFormProps>= ({onCancel}) =>{
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector(state => state.transaction);
    const [managers, setManagers] = useState<ManagerLight[] | null>(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        setValue,
        control} = useForm({
        defaultValues: {
            ...initialData,
            createdAt: new Date().toISOString(),
        }
    });

    useEffect(()=>{
        setValue('managersId',managers);
    },[managers]);

    useEffect(()=>{
        const getManagersList = async (id: number) =>{
            const {data: {managers: managersData}}: AxiosResponse =
                await axiosInstance.get(CLIENT_MANAGERS_LIGHT_LIST + id );
            return managersData ? managersData : null;
        };
        if(getValues('clientId')){
            getManagersList(Number(getValues('clientId')?.id)).then(r=>{
                setManagers(r);
            });
        } else {
            setManagers(null);
            setValue('managersId',null);
        }
    },[getValues('clientId')]);

    const onSubmit = (data: ITransactionOutputForm) => {
        if(!data.commission)data.commission=null;
        if(data.managersId)data.managersId=data.managersId.map(( el: any)=>el?.id);
        if(data.clientId)data.clientId=data.clientId.id;
        dispatch(createTransaction(data));
    };
    watch('clientId');
    watch('createdAt');
    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography variant={'h5'} align={'center'}>Вывод</Typography>
            <ClientSelect
                sx={{m:'5px 0', width: '100%'}}
                register={register}
                errors={errors}
                name={'clientId'}
                control={control}
            />
            {managers && <Controller
                control={control}
                name={'managersId'}
                render={({ field: { onChange } }) => (
                    <AutocompleteField
                        multiple={true}
                        onChangeRender={onChange}
                        targetValue={'id'}
                        label="Менеджеры"
                        sx={{mt: '15px', width: '100%'}}
                        dataOptions={managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        displayValue1="nickName"
                        defaultValue={managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        inputProps={{
                            ...register('managersId',
                                {
                                    required: {value: true, message: 'выберите менеджера'},
                                }),
                            error: !!errors.managersId,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore:next-line
                            helperText:  errors.managersId ? errors.managersId?.message : null

                        }}
                    />)}
            /> }
            <PaymentSelect sx={{mt:'5px'}} register={register} errors={errors}/>
            <TextField
                sx={{m:'5px 0'}}
                margin="normal"
                error={!!errors.numberWallet}
                helperText={errors.numberWallet ? errors.numberWallet.message : null}
                fullWidth
                size='small'
                label="Номер кошелька"
                id="numberWallet"
                {...register('numberWallet',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.commission}
                helperText={errors.commission ? errors.commission.message : null}
                size='small'
                fullWidth
                type="number"
                label="Комиссия"
                id="commission"
                {...register('commission',{
                    setValueAs: v => v ? parseFloat(v) : v,
                })}
            />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.paymentId}
                helperText={errors.paymentId ? errors.paymentId.message : null}
                size='small'
                fullWidth
                label="ID транзакции"
                id="paymentId"
                {...register('paymentId')}
            />
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value ?? '')}
                value={getValues('createdAt')}
                sx={{
                    margin: '5px 0'
                }}
            />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                id="comment"
                {...register('comment')}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Вывести
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
