import {profileUpdate, setLoading, setUser} from './reducer';
import {AppDispatch} from '../index';
import {LoginRequestDto} from '../dtos/login.request.dto';
import {appHistory} from '../../router/history';
import { getDefaultPathByRole } from '../../router/routes-path';
import { LOGIN, MANAGER_UPDATE } from '../../constants/api-endpoints';
import axiosInstance from '../../config/axios';
import { UserResponseDto } from '../dtos/user.response.dto';
import { AxiosResponse } from 'axios';

import { closeModal } from '../modal/reducer';
import { ErrorMessages } from '../../constants/errors';
import { showSnackbar } from '../snackbar/reducer';
import { IUser } from '../../interfaces/user.interface';
import { ResponseMessage } from '../../constants/response.messages';

export const authLogin = ({email, password}: LoginRequestDto) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setLoading(true));
        const {data:{accessToken,...user}}: AxiosResponse<UserResponseDto> =
          await axiosInstance.post(LOGIN, {email, password});
        dispatch(setUser({
            isAuth: true,
            accessToken,
            user
        }));
        localStorage.setItem('accessToken',accessToken);
        appHistory.push(getDefaultPathByRole(user.role));
    } catch (e) {
        dispatch(showSnackbar(ErrorMessages.AUTH_FAILED, 'error'));
    } finally {
        dispatch(setLoading(false));
    }
};

export const authLogout = () => async (dispatch: AppDispatch) => {
    try {
        localStorage.removeItem('accessToken');
        dispatch(setUser({
            isAuth: false,
            user: null
        }));

    } catch (e) {
        throw new Error('Logout failed');
    }
};

export const updateProfile = (body: IUser) =>async (dispatch: AppDispatch,getState: any)=>{
    const {auth:{user:{id}}} = getState();
    try{
        const {data}: AxiosResponse = await axiosInstance.patch(`${MANAGER_UPDATE}${id}`,body);
        dispatch(profileUpdate(data));
        dispatch(closeModal());
        dispatch(showSnackbar(ResponseMessage.UPDATED_SUCCESS, 'success'));
    } catch (e) {
        dispatch(showSnackbar(ErrorMessages.UPDATE_DATA, 'error'));
    }
};
