import React  from 'react';
import { Box, Modal } from '@mui/material';
import classes from '../modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';
import { ITransactionOutputForm } from '../TransactionOutput/TransactionOutputForm';
import {UpdateOutputTransactionForm} from './UpdateOutputTransactionForm';
import { updateOutputTransaction } from '../../../store/output-transaction/thunk';

export interface UpdateTransactionOutputForm extends Omit<ITransactionOutputForm, 'clientId' | 'type' | 'payment'>{
    payment: number | null;
    id: number;
    paymentInfo: {
        id: number;
    }
}

export const UpdateOutputTransaction: React.FC = () =>{

    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector(state => state.client);
    const { data } = useAppSelector(state => state.modal);

    const onCloseModal = () => dispatch(closeModal());

    const onSubmit = (formData: UpdateTransactionOutputForm) => {
        dispatch(updateOutputTransaction({...formData, payment: Number(formData.payment)}));
    };

    return (
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <UpdateOutputTransactionForm
                    onCancel={onCloseModal}
                    onSubmit={onSubmit}
                    outputTransactionData={data.transaction}
                    isLoading={isLoading}
                />

            </Box>
        </Modal>
    );
};
