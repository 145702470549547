import React from 'react';
import { PaymentType } from '../TransactionInput/TransactionInputForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {Controller, useForm} from 'react-hook-form';
import { Avatar, Box, Button, TextField } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { createTransaction } from '../../../store/transaction/thunk';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import AutocompleteField from '../../AutocompleteField';

export interface ITransactionOutputForm {
  clientId: number | null,
  numberWallet: string | null,
  payment: string | null,
  commission: number | null,
  paymentId: string | null,
  type: PaymentType,
  revision: number | null,
  comment: string |null,
  managersId: any[] | null,
  createdAt: string | null,
}

const initialData: ITransactionOutputForm = {
    clientId: null,
    revision: null,
    numberWallet: null,
    payment: null,
    commission: null,
    paymentId: null,
    type: PaymentType.output,
    comment: null,
    managersId: null,
    createdAt: null,
};

interface TransactionOutputFormProps {
  onCancel(): void
}

export const TransactionOutputForm: React.FC<TransactionOutputFormProps>= ({onCancel}) =>{
    const dispatch = useAppDispatch();

    const { data: client } = useAppSelector(state => state.client);
    const { isLoading } = useAppSelector(state => state.transaction);
    const { register, handleSubmit, formState: { errors },control } = useForm({
        defaultValues: {
            ...initialData,
            clientId: client?.id,
            managersId: client?.managers
        }
    });

    const onSubmit = (data: ITransactionOutputForm) => {
        if(!data.commission)data.commission=null;
        if(data.managersId)data.managersId = data.managersId.map(el=>el.id);
        dispatch(createTransaction(data));
    };

    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems:'center'
            }}>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <PermContactCalendarIcon />
                </Avatar>
                <div style={{marginTop: '5px'}}>{client?.nickName}</div>
            </Box>
            {client && <Controller
                control={control}
                name={'managersId'}
                render={({ field: { onChange } }) => (
                    <AutocompleteField
                        multiple={true}
                        onChangeRender={onChange}
                        targetValue={'id'}
                        label="Менеджеры"
                        sx={{mt: '15px', width: '100%'}}
                        dataOptions={client.managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        displayValue1="nickName"
                        defaultValue={client.managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        inputProps={{
                            ...register('managersId',
                                {
                                    required: {value: true, message: 'выберите менеджера'},
                                }),
                            error: !!errors.managersId,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore:next-line
                            helperText:  errors.managersId ? errors.managersId?.message : null

                        }}
                    />)}
            /> }
            <PaymentSelect register={register} errors={errors}/>
            <TextField
                sx={{fontSize:'5px'}}
                margin="normal"
                error={!!errors.numberWallet}
                helperText={errors.numberWallet ? errors.numberWallet.message : null}
                fullWidth
                size='small'
                label="Номер кошелька"
                id="numberWallet"
                {...register('numberWallet',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.commission}
                helperText={errors.commission ? errors.commission.message : null}
                size='small'
                fullWidth
                type="number"
                label="Комиссия"
                id="commission"
                {...register('commission',{
                    setValueAs: v => v ? parseFloat(v) : v,
                })}
            />
            <TextField
                margin="normal"
                error={!!errors.paymentId}
                helperText={errors.paymentId ? errors.paymentId.message : null}
                size='small'
                fullWidth
                label="ID транзакции"
                id="paymentId"
                {...register('paymentId')}
            />
            <TextField
                margin="normal"
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                id="comment"
                {...register('comment')}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                {client && <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Вывести
                </LoadingButton>}
            </Box>
        </Box>
    </>;
};
