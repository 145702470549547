import {createSlice, Draft} from '@reduxjs/toolkit';

export interface IRegion{
    id: number;
    name: string;
}

export interface IRegionsState {
    data: IRegion[];
    isLoading: boolean,
    error: string | null;
}

const initialState: IRegionsState = {
    data: [],
    isLoading: false,
    error: null,
};

export const regionsSlice = createSlice({
    name: 'regions',
    initialState,
    reducers:{
        setLoadingRegions: (state: Draft<IRegionsState>, { payload }) => {
            state.isLoading = payload;
        },
        setRegions: (state: Draft<IRegionsState>, { payload }) => {
            state.data = payload;
        },
        setRegionsError: (state: Draft<IRegionsState>, { payload }) => {
            state.error = payload;
        },
    }
});

export const {
    setLoadingRegions,
    setRegions,
    setRegionsError,
} = regionsSlice.actions;

export default  regionsSlice.reducer;
