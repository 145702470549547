import React, {useEffect, useState} from 'react';
import { Avatar, Box, Button, TextField } from '@mui/material';
import {useForm} from 'react-hook-form';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import { IClient } from '../../../interfaces/client.interface';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import IsAdmin from '../../../hooks/isAdmin';
import AutocompleteField from '../../AutocompleteField';
import { getManagerLight } from '../../../store/managerLight/thunks';

export interface IAutocomplitValue {
    label: string | undefined,
    value: string | undefined,
}

interface EditClientFormProps {
    onCancel(): void;
    onSubmit(formData: IClient): void;
    clientData: IClient | null;
    isLoading: boolean;
}

const initialState = {
    nickName: '',
    phone: '',
    telegram: '',
    description: '',
    managers: [],
};

export const EditClientForm: React.FC<EditClientFormProps> = ({onCancel, onSubmit, clientData, isLoading}) =>{
    const dispatch = useAppDispatch();

    const {data} = useAppSelector(state => state.managerLight);
    const [managerValue, setManagerValue] = useState<number[] | null>(
        clientData ? clientData.managers.map(el=>el.id) : null
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm<Omit<IClient, 'managers'> & {managersId: number[] | null}>({
        defaultValues: clientData ?
            {
                ...clientData,
                managersId: clientData.managers.map(el=>el.id)
            } : initialState
    });

    const isAdmin = IsAdmin();

    useEffect(()=>{
        setValue('managersId',managerValue);
    },[managerValue]);

    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <PermContactCalendarIcon />
                </Avatar>
            </Box>

            <TextField
                sx={{fontSize:'5px'}}
                margin="normal"
                error={!!errors.nickName}
                helperText={errors.nickName ? errors.nickName.message : null}
                required
                fullWidth
                disabled={!isAdmin}
                size='small'
                label="Ник"
                autoComplete="nickName"
                id="nickName"
                {...register('nickName',
                    {
                        required: {value: true, message: 'введите имя'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : null}
                // required
                fullWidth
                size='small'
                label="Телефон"
                autoComplete="phone"
                id="phone"
                {...register('phone',
                    {
                        // required: {value: true, message: 'введите телефон'},
                        maxLength: {value: 13, message: 'длинна не больше 13 символов'},
                        minLength: {value: 10, message: 'минимальная длинна 10 символа' }
                    })}
            />
            {(isAdmin && clientData) &&
            <AutocompleteField
                multiple={true}
                key={'managersId'}
                targetValue={'id'}
                label="Менеджеры"
                sx={{mt: '15px', width: '100%'}}
                selectedValue={managerValue}
                setSelectedValue={setManagerValue}
                fetchData={() => dispatch(getManagerLight())}
                dataOptions={data}
                displayValue1="nickName"
                defaultValue={clientData.managers.map(el=>({id:el.id, nickName: el.nickName}))}
            />}

            <TextField
                margin="normal"
                error={!!errors.telegram}
                helperText={errors.telegram ? errors.telegram.message : null}
                fullWidth
                size='small'
                label="Телеграм"
                autoComplete="telegram"
                id="telegram"
                {...register('telegram',
                    {
                        maxLength: {value: 15, message: 'длинна не больше 15 символов'},
                        minLength: {value: 4, message: 'минимальная длинна 4 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.description}
                helperText={errors.description ? errors.description.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                autoComplete="description"
                id="description"
                {...register('description')}
            />

            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Изменить
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
