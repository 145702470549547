import {createSlice , Draft} from '@reduxjs/toolkit';
import {IRegion} from '../regions/regions-reducer';

export interface ISalary {
    id: number;
    firstName: string;
    lastName: string;
    total_input: number;
    total_output: number;
    total_balance: number;
    paid_salary: number;
    paid_salary_ua: number;
    have_to_pay: number;
    difference_salary: number;
}

export interface IRegionSalary {
    total_salary: number;
    region: IRegion;
}

export interface IUserLight {
    id: number;
    firstName: string;
    lastName: string;
}

export interface ISalaryLog {
    id: number;
    managerId: number;
    salary: number;
    regionId: number;
    exchangeRate: number;
    createdAt: string;
    user: IUserLight;
    region: IRegion;
    from:string;
    to: string;
}

export interface ISalaryState {
    salary: {
        data: ISalary[];
        differenceRate: number;
        totalManagerNeedPay: number;
        sumPaidSalary: number;
        count: number;
        isLoading: boolean;
    };
    regions: {
        data: IRegionSalary[];
        count: number;
        isLoading: boolean;
    };
    logs: {
        data: ISalaryLog[];
        count: number;
        isLoading: boolean;
    };
    error: string | null;
}

const initialState: ISalaryState = {
    salary: {
        data: [],
        differenceRate: 0,
        totalManagerNeedPay: 0,
        sumPaidSalary: 0,
        count: 0,
        isLoading: false,
    },
    regions: {
        data: [],
        count: 0,
        isLoading: false,
    },
    logs: {
        data: [],
        count: 0,
        isLoading: false,
    },
    error: null,
};

export const salarySlice = createSlice({
    name: 'salary',
    initialState,
    reducers:{
        setLoadingSalary: (state: Draft<ISalaryState>,{payload}) =>{
            state.salary.isLoading = payload;
        },
        setSalary: (state: Draft<ISalaryState>,{payload}) =>{
            state.salary.data = payload.data;
            state.salary.count = payload.count;
            state.salary.differenceRate = payload.differenceRate;
            state.salary.totalManagerNeedPay = payload.totalManagerNeedPay;
            state.salary.sumPaidSalary = payload.sumPaidSalary;
        },
        setSalaryError: (state: Draft<ISalaryState>,{payload}) =>{
            state.error = payload;
        },
        setLoadingSalaryRegions: (state: Draft<ISalaryState>,{payload}) =>{
            state.regions.isLoading = payload;
        },
        setSalaryRegions: (state: Draft<ISalaryState>,{payload}) =>{
            state.regions.data = payload.data;
            state.regions.count = payload.count;
        },
        setSalaryRegionsError: (state: Draft<ISalaryState>,{payload}) =>{
            state.error = payload;
        },
        setLoadingSalaryLogs: (state: Draft<ISalaryState>,{payload}) =>{
            state.logs.isLoading = payload;
        },
        setSalaryLogs: (state: Draft<ISalaryState>,{payload}) =>{
            state.logs.data = payload.data;
            state.logs.count = payload.count;
        },
        setSalaryLogsError: (state: Draft<ISalaryState>,{payload}) =>{
            state.error = payload;
        },
    }
});

export const {
    setLoadingSalary,
    setSalary,
    setSalaryError,
    setLoadingSalaryRegions,
    setSalaryRegions,
    setSalaryRegionsError,
    setLoadingSalaryLogs,
    setSalaryLogs,
    setSalaryLogsError
} = salarySlice.actions;

export default salarySlice.reducer;
