import React from 'react';
import { Avatar, Box, Button, TextField } from '@mui/material';
import {useForm} from 'react-hook-form';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { IUser } from '../../../interfaces/user.interface';
import IsAdmin from '../../../hooks/isAdmin';

interface IProfileForm {
    onCancel(): void;
    onSubmit(formData: IUser): void;
    managerData: IUser | null;
    isLoading?: boolean;
}

export const ProfileForm: React.FC<IProfileForm> = ({onCancel,onSubmit,managerData}) =>{
    const isAdmin = IsAdmin();

    const { register, handleSubmit, formState: { errors } , watch} = useForm({
        defaultValues: {
            email: managerData?.email,
            nickName: managerData?.nickName,
            firstName: managerData?.firstName,
            lastName: managerData?.lastName,
            boss: managerData?.boss,
            phone: managerData?.phone,
            telegram: managerData?.telegram,
            password: null,
            confirmPassword: null
        }
    });

    const onSubmitHandler = (data : IUser & {confirmPassword: string}) =>{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { confirmPassword, password,...sendData} = data;
        if(managerData){
            onSubmit({
                ...sendData,
                password,
            });
        }

    };

    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <PermContactCalendarIcon />
                </Avatar>
            </Box>

            <TextField
                disabled
                margin="normal"
                fullWidth
                id="email"
                label="Login"
                autoComplete="email"
                size='small'
                {...register('email')}
            />
            <TextField
                sx={{fontSize:'5px'}}
                disabled={!isAdmin}
                margin="normal"
                error={!!errors.nickName}
                helperText={errors.nickName ? errors.nickName.message : null}
                required
                fullWidth
                size='small'
                label="Ник"
                autoComplete="nickName"
                id="nickName"
                {...register('nickName',
                    {
                        required: {value: true, message: 'введите ник'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{fontSize:'5px'}}
                disabled={!isAdmin}
                margin="normal"
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : null}
                required
                fullWidth
                size='small'
                label="Фамилия"
                autoComplete="firstName"
                id="firstName"
                {...register('firstName',
                    {
                        required: {value: true, message: 'введите фамилию'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.lastName}
                disabled={!isAdmin}
                helperText={errors.lastName ? errors.lastName.message : null}
                required
                fullWidth
                size='small'
                label="Имя"
                autoComplete="lastName"
                id="lastName"
                {...register('lastName',
                    {
                        required: {value: true, message: 'введите имя'},
                        maxLength: {value: 20, message: 'длинна не больше 20 символов'},
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : null}
                required
                fullWidth
                size='small'
                label="Телефон"
                autoComplete="phone"
                id="phone"
                {...register('phone',
                    {
                        required: {value: true, message: 'введите телефон'},
                        maxLength: {value: 13, message: 'длинна не больше 13 символов'},
                        minLength: {value: 10, message: 'минимальная длинна 10 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.telegram}
                helperText={errors.telegram ? errors.telegram.message : null}
                fullWidth
                size='small'
                label="Телеграм"
                autoComplete="telegram"
                id="telegram"
                {...register('telegram',
                    {
                        maxLength: {value: 15, message: 'длинна не больше 15 символов'},
                        minLength: {value: 4, message: 'минимальная длинна 4 символа' }
                    })}
            />
            <TextField
                margin="normal"
                error={!!errors.boss}
                disabled={!isAdmin}
                helperText={errors.boss ? errors.boss.message : null}
                size='small'
                fullWidth
                label="Старший"
                autoComplete="boss"
                id="boss"
                {...register('boss',
                    {
                        maxLength: {value: 40, message: 'длинна не больше 40 символов'},
                        minLength: {value: 5, message: 'минимальная длинна 5 символа' }
                    })}
            />
            {isAdmin && <>
                <TextField
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : null}
                    fullWidth
                    size='small'
                    label="Новый пароль"
                    type="password"
                    id="password"
                    autoComplete="password"
                    {...register('password',
                        {
                            maxLength: {value: 10, message: 'длинна не больше 10 символов'},
                            minLength: {value: 5, message: 'минимальная длинна 5 символа' }
                        })}
                />
                <TextField
                    margin="normal"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : null}
                    fullWidth
                    size='small'
                    label="Подтвердите пароль"
                    type="password"
                    id="confirmPassword"
                    autoComplete="password"
                    {...register('confirmPassword',
                        {
                            validate: (value)=> value ===watch('password') || 'Пароли не совпадают'
                        })}
                />
            </>}
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{flexBasis: '45%'}}
                >
                    Изменить
                </Button>
            </Box>
        </Box>
    </>;
};
