import {useForm} from 'react-hook-form';
import {Box, Button, TextField, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import { updateTransfer } from '../../../store/transfers/thunk';
import DateSelect from '../../DateSelect';
import {usePermission} from '../../Permission';
import {EUserRoles} from '../../../enums/roles';
import {RegionSelect} from '../../RegionSelect/RegionSelect';

export interface IUpdateTransferForm {
    paymentId: number;
    toPaymentId: number | null;
    currency: number;
    commission: number;
    regionId: number | string;
    comment: string;
}

interface UpdateTransferFormProps {
    onCancel(): void
    transfers: any;
}

export const UpdateTransferForm: React.FC<UpdateTransferFormProps> =({onCancel, transfers})=>{
    const dispatch = useAppDispatch();
    const isPermissions = usePermission([EUserRoles.ADMIN]);
    const {isLoading} = useAppSelector(state => state.transfers);
    const { register, handleSubmit, formState: { errors },setValue,watch,getValues} = useForm({
        defaultValues: {
            ...transfers,
            regionId: transfers?.region.id.toString(),
            toPaymentId: transfers?.toPaymentId ?? null }
    });
    watch('createdAt');
    const onSubmit = (data: IUpdateTransferForm) => {
        dispatch(
            updateTransfer(transfers.id, {...data, regionId: Number(data.regionId)})
            );
    };

    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center'
            }} variant={'h6'}>Редактировать перевод</Typography>
            <PaymentSelect 
                register={register} 
                errors={errors} 
                name={'paymentId'} 
                label='Платежка с'
                defaultValue={transfers.paymentId}/>
            <PaymentSelect 
                register={register} 
                errors={errors} 
                required={false}
                name={'toPaymentId'} 
                label='Платежка на'
                defaultValue={transfers.toPaymentId}/>
            <TextField
                margin="normal"
                error={!!errors.currency}
                helperText={errors.currency ? errors.currency.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="currency"
                {...register('currency',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    }
                )}
            />
            <TextField
                margin="normal"
                error={!!errors.commission}
                helperText={errors.commission ? errors.commission.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Комиссия"
                id="commission"
                {...register('commission',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите комиссию'},
                    })}
            />
            <RegionSelect
                sx={{mt: '5px', width: '100%'}}
                register={register}
                errors={errors}
                name={'regionId'}
                value={getValues('regionId')}
            />
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value ?? '')}
                value={getValues('createdAt')}
                sx={{
                    margin: '5px 0'
                }}
                disabled={!isPermissions}
            />
            <TextField
                margin="normal"
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                required
                maxRows={3}
                size='small'
                label="Комментарий"
                autoComplete="comment"
                id="comment"
                {...register('comment',{
                    required: {value: true, message: 'введите комментарий'},
                })}
            />

            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Редактировать
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
