import {createSlice, Draft} from '@reduxjs/toolkit';
import { IRegion } from '../regions/regions-reducer';

export interface IExpense {
    id: number;
    paymentId: number;
    regionId: number;
    region: IRegion;
    paymentInfo: {
        id: number;
        name: string;
    };
    revision: number;
    commission: number;
    amountWithCommission: number;
    comment: string;
    transactionId: string;
    createdAt: string;
    updatedAt: string;
}

export interface IExpensesState {
    data: IExpense[];
    count: number;
    total: {
        total_amount: number | null;
        total_commission:  number | null;
        total_amountWithCommission:  number | null;
    };
    isLoading: boolean,
    error: string | null;
}

const initialState: IExpensesState = {
    count: 0,
    total: {
        total_amount: null,
        total_commission:  null,
        total_amountWithCommission:  null,
    },
    data: [],
    isLoading: false,
    error: null,
};

export const expensesSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        setExpenses: (state: Draft<IExpensesState>, {payload}) => {
            state.data = payload.data;
            state.count = payload.count;
            state.total = payload.total;
        },
        setLoadingExpenses: (state: Draft<IExpensesState>, {payload}) => {
            state.isLoading = payload;
        },
        setExpensesError: (state: Draft<IExpensesState>,  {payload}) => {
            state.error = payload;
        },
    }
});

export const {
    setExpenses,
    setLoadingExpenses,
    setExpensesError,
} = expensesSlice.actions;

export default expensesSlice.reducer;
