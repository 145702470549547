import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, TextField, Typography} from '@mui/material';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import { ClientSelect } from '../../ClientSelect';
import { createTransaction } from '../../../store/transaction/thunk';
import {AxiosResponse} from 'axios';
import axiosInstance from '../../../config/axios';
import {CLIENT_MANAGERS_LIGHT_LIST} from '../../../constants/api-endpoints';
import AutocompleteField from '../../AutocompleteField';
import DateSelect from '../../DateSelect';

export enum PaymentType {
  input=1,
  output=2
}

export interface ManagerLight {
    id: number,
    nickName: string,
}

interface ITransactionInputForm {
  clientId: any,
  managersId: any[] | null,
  crypto: string | null,
  dirtyMoney: number | null,
  revision: number | null,
  payment: string | null,
  paymentId: string | null,
  type: PaymentType,
  unrealisticAmounts: string | null,
  comment: string |null,
  createdAt: string | null
}

const initialData: ITransactionInputForm = {
    clientId: null,
    revision: null,
    managersId: null,
    crypto:null,
    dirtyMoney:null,
    payment: '',
    paymentId: null,
    comment: null,
    type: PaymentType.input,
    unrealisticAmounts: null,
    createdAt: null
};

interface TransactionInputFormProps {
  onCancel(): void
}

export const TransactionInputFormPage: React.FC<TransactionInputFormProps> = ({onCancel}) =>{
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector(state => state.transaction);
    const [managers, setManagers] = useState<ManagerLight[] | null>(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        setValue,
        control} = useForm({
        defaultValues: {
            ...initialData,
            createdAt: new Date().toISOString(),
        }
    });

    useEffect(()=>{
        setValue('managersId',managers);
    },[managers]);

    useEffect(()=>{
        const getManagersList = async (id: number) =>{
            const {data: {managers: managersData}}: AxiosResponse =
                await axiosInstance.get(CLIENT_MANAGERS_LIGHT_LIST + id );
            return managersData ? managersData : null;
        };
        if(getValues('clientId')){
            getManagersList(Number(getValues('clientId')?.id)).then(r=>{
                setManagers(r);
            });
        } else {
            setManagers(null);
            setValue('managersId',null);
        }
    },[getValues('clientId')]);
    watch('dirtyMoney');
    const dirtyMoney = getValues('dirtyMoney');
    
    useEffect(()=>{
        dirtyMoney && setValue('revision',dirtyMoney*0.995);
    },[dirtyMoney]);

    const onSubmit = (data: ITransactionInputForm) => {
        if(!data.dirtyMoney)data.dirtyMoney=null;
        if(data.managersId)data.managersId=data.managersId.map(( el: any)=>el?.id);
        if(data.clientId)data.clientId=data.clientId.id;
        dispatch(createTransaction(data));
    };
    watch('clientId');
    watch('createdAt');
    return <>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography variant={'h5'} align={'center'}>Пополнение</Typography>
            <ClientSelect
                sx={{m:'5px 0', width: '100%'}}
                register={register}
                errors={errors}
                name={'clientId'}
                control={control}
            />
            {managers && <Controller
                control={control}
                name={'managersId'}
                render={({ field: { onChange } }) => (
                    <AutocompleteField
                        multiple={true}
                        onChangeRender={onChange}
                        targetValue={'id'}
                        label="Менеджеры"
                        sx={{mt: '15px', width: '100%'}}
                        dataOptions={managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        displayValue1="nickName"
                        defaultValue={managers.map(el=>({id:el.id, nickName: el.nickName}))}
                        inputProps={{
                            ...register('managersId',
                                {
                                    required: {value: true, message: 'выберите менеджера'},
                                }),
                            error: !!errors.managersId,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore:next-line
                            helperText:  errors.managersId ? errors.managersId?.message : null

                        }}
                    />)}
            /> }
            <TextField
                sx={{m:'10px 0'}}
                margin="normal"
                error={!!errors.crypto}
                helperText={errors.crypto ? errors.crypto.message : null}
                fullWidth
                size='small'
                label="Крипта"
                id="crypto"
                {...register('crypto',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />
            <TextField
                sx={{m:'5px 0'}}
                margin="normal"
                error={!!errors.paymentId}
                helperText={errors.paymentId ? errors.paymentId.message : null}
                fullWidth
                size='small'
                label="ID платежа"
                id="paymentId"
                {...register('paymentId',
                    {
                        minLength: {value: 2, message: 'минимальная длинна 2 символа' }
                    })}
            />

            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.dirtyMoney}
                helperText={errors.dirtyMoney ? errors.dirtyMoney.message : null}
                fullWidth
                required
                size='small'
                type="number"
                placeholder='Cумма грязными'
                label="Cумма грязными"
                InputLabelProps={{
                    shrink: true,
                  }}
                id="dirtyMoney"
                {...register('dirtyMoney',{
                    setValueAs: v => v ? parseFloat(v) : v,
                    required: {value: true, message: 'введите сумму'},
                })}
            />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                size='small'
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder='Сумма'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <PaymentSelect sx={{m:'5px 0'}} register={register} errors={errors} />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.unrealisticAmounts}
                helperText={errors.unrealisticAmounts ? errors.unrealisticAmounts.message : null}
                size='small'
                fullWidth
                multiline
                rows={2}
                label="Накрутил вручную"
                id="unrealisticAmounts"
                {...register('unrealisticAmounts')}
            />
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value ?? '')}
                value={getValues('createdAt')}
                sx={{
                    margin: '5px 0'
                }}
            />
            <TextField
                margin="normal"
                sx={{m:'5px 0'}}
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="Комментарий"
                id="comment"
                {...register('comment')}
            />
            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Пополнить
                </LoadingButton>
            </Box>
        </Box>
    </>;

};
