import React, {useEffect} from 'react';
import {useAppSelector} from '../../../store/hooks';
import {Controller, useForm} from 'react-hook-form';
import { Box, Button, TextField, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import HourglassFullTwoToneIcon from '@mui/icons-material/HourglassFullTwoTone';
import {PaymentSelect} from '../../PaymentSelect/PaymentSelect';
import { IExpense } from '../../../store/expenses/expenses-reducer';
import {PermissionHOC, usePermission} from '../../Permission';
import {EUserRoles} from '../../../enums/roles';
import AutocompleteField from '../../AutocompleteField';
import {getRegions} from '../../../store/regions/thunk';
import {useDispatch} from 'react-redux';
import DateSelect from '../../DateSelect';

// export interface IUpdateExpenseForm {
//     regionId: number;
//     revision: number;
//     commission: number;
//     paymentId: number;
//     comment: string;
//     transactionId: string;
// }

interface UpdateExpenseFormProps {
    onCancel(): void;
    onSubmit(data: IExpense): void;
    expensesData: IExpense;
}
export const UpdateExpenseForm: React.FC<UpdateExpenseFormProps> =({onCancel, onSubmit, expensesData})=>{
    const {isLoading} = useAppSelector(state => state.expenses);
    const {data} = useAppSelector(state => state.regions);
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors },setValue ,control,getValues,watch} = useForm({
        defaultValues: {...expensesData, regionId: expensesData.region }
    });
    watch('createdAt');
    useEffect(()=>{
        dispatch(getRegions());
    },[]);

    const formSubmit = (data:any) =>{
        const {regionId,...payload} = data;
        if (regionId && payload){
            onSubmit({...payload, regionId:regionId.id});
        }
    };

    const isPermissions = usePermission([EUserRoles.ADMIN]);

    return <>
        <Box component="form" onSubmit={handleSubmit(formSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography sx={{
                display: 'flex',
                justifyContent: 'center'
            }} variant={'h6'}>Редактировать расход</Typography>
            <PaymentSelect
                disabled={!isPermissions}
                register={register}
                errors={errors}
                name={'paymentId'}
                defaultValue={expensesData.paymentId}
            />
            <TextField
                margin="normal"
                disabled={!isPermissions}
                error={!!errors.revision}
                helperText={errors.revision ? errors.revision.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Сумма"
                id="revision"
                {...register('revision',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите сумму'},
                    })}
            />
            <TextField
                margin="normal"
                disabled={!isPermissions}
                error={!!errors.commission}
                helperText={errors.commission ? errors.commission.message : null}
                required
                fullWidth
                type="number"
                size='small'
                label="Комиссия"
                id="commission"
                {...register('commission',
                    {
                        setValueAs: v => v ? parseFloat(v) : v,
                        required: {value: true, message: 'введите комиссию'},
                    })}
            />
            <PermissionHOC roles={[EUserRoles.ADMIN]}>
                {data && <Controller
                    control={control}
                    name={'regionId'}
                    render={({ field: { onChange } }) => (
                        <AutocompleteField
                            multiple={false}
                            onChangeRender={onChange}
                            key={'regionId'}
                            targetValue={'id'}
                            value={getValues('regionId')}
                            label="Регионы"
                            sx={{mt: '15px', width: '100%'}}
                            // fetchData={() => dispatch(getRegions())}
                            dataOptions={data}
                            displayValue1="name"
                            inputProps={{
                                ...register('regionId',
                                    {
                                        required: {value: true, message: 'выберите регион'},
                                    }),
                                error: !!errors.regionId,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore:next-line
                                helperText:  errors.regionId ? errors.regionId?.message : null

                            }}
                        />)}
                />}
            </PermissionHOC>
            <TextField
                margin="normal"
                error={!!errors.comment}
                helperText={errors.comment ? errors.comment.message : null}
                fullWidth
                multiline
                required={true}
                maxRows={3}
                size='small'
                label="Комментарий"
                autoComplete="comment"
                id="comment"
                {...register('comment',{
                    required: {value: true, message: 'введите комментарий'},
                })}
            />
            <DateSelect
                onChangeHandler={value=>setValue('createdAt',value ?? '')}
                value={getValues('createdAt')}
                sx={{
                    margin: '5px 0'
                }}
                disabled={!isPermissions}
            />
            <TextField
                margin="normal"
                disabled={!isPermissions}
                error={!!errors.transactionId}
                helperText={errors.transactionId ? errors.transactionId.message : null}
                fullWidth
                multiline
                maxRows={3}
                size='small'
                label="ID транзакции"
                autoComplete="transactionId"
                id="transactionId"
                {...register('transactionId',{
                    minLength: {value: 2, message: 'минимальная длинна 2 символа'},
                })}
            />

            <Box sx={{
                display:'flex',
                justifyContent: 'space-between',
                mt:2,
            }}>
                <Button
                    onClick={onCancel}
                    fullWidth
                    variant='outlined'
                    sx={{flexBasis: '45%'}}
                >
                    Отмена
                </Button>
                <LoadingButton
                    loading={isLoading}
                    sx={{flexBasis: '45%'}}
                    type="submit"
                    loadingIndicator={<HourglassFullTwoToneIcon/>}
                    variant="outlined">
                    Редактировать
                </LoadingButton>
            </Box>
        </Box>
    </>;
};
