import axios from 'axios';
import {BASE_URL} from '../constants/api-endpoints';
import { store } from '../store';
import { setUser } from '../store/auth/reducer';
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = {...config.headers, Authorization: `Bearer ${localStorage.getItem('accessToken')}`};
    return config;
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        store.dispatch(setUser({
            isAuth: false,
            user: null
        }));
    }
    return Promise.reject(error);
});

export default axiosInstance;
