import React from 'react';
import classes from '../modal.module.scss';
import { Box, Modal } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closeModal } from '../../../store/modal/reducer';
import EditManagerForm from './EditManagerForm';
import { updateManager } from '../../../store/manager/thunk';
import { IUser } from '../../../interfaces/user.interface';

function EditManager () {
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector(state => state.manager);
    const { data: managerData } = useAppSelector(state => state.modal);
    const onCloseModal = () => dispatch(closeModal());

    const onSubmit = (formData: IUser) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { password, regionId,...sendData} = formData;
        dispatch(updateManager({...sendData, password, regionId: +regionId}));
    };

    return (
        <Modal
            sx={{
                position:'absolute',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <EditManagerForm
                    onCancel={onCloseModal}
                    onSubmit={onSubmit}
                    managerData={managerData}
                    isLoading={isLoading}
                />

            </Box>
        </Modal>
    );
}

export default EditManager;
