import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {EUserRoles} from '../enums/roles';
import {routersPath} from './routes-path';
import {appHistory} from './history';
import {Dashboard} from '../containers/Dashboard/Dashboard';
import {useAppSelector} from '../store/hooks';
import {IAuth} from '../store/auth/reducer';

interface PrivateRouteProps {
    component: any,
    roles?: EUserRoles[],
    path: string,
    exact?: boolean,
    title?: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = (
    {component: Component,
        roles, title, ...rest}) => {

    const auth = useAppSelector<IAuth>(state => state.auth);

    const { isAuth, user } = auth;
    const userHasRequiredRole = user ? roles?.includes(user.role) : false;

    return (
        <Route
            {...rest}
            render={props =>
                isAuth && userHasRequiredRole ? (
                    <Dashboard title={title}>
                        <Component {...props} />
                    </Dashboard>
                ) :(
                    <Redirect to={{
                        pathname: routersPath.LOGIN_PAGE,
                        state: { from: appHistory.location }
                    }} />
                )
            }
        />
    );
};

export default PrivateRoute;
