import React from 'react';
import {IRoute} from '../../interfaces/router/route.interface';
import {routersPath} from '../routes-path';
const LoginPage = React.lazy(() => import('../../pages/Login'));

export const authRoutes: IRoute[] = [{
    path: routersPath.LOGIN_PAGE,
    exact: true,
    component: LoginPage,
    title: 'Логин'
}];
