import { AppDispatch, store } from '../index';
import { AxiosResponse } from 'axios';
import axiosInstance from '../../config/axios';
import {
    setLoadingManagers, setManagers,
    updateManagerAtTable } from './manager-list-reducer';
import {
    MANAGER_CREATE,
    MANAGER_REMOVE,
    MANAGER_UPDATE,
    MANAGERS_LIST,
    USER_LOGS,
    INTERMEDIATE_LIST
} from '../../constants/api-endpoints';
import { closeModal } from '../modal/reducer';
import { setLoadingManager, setManager, setManagerLogs, setManagerLogsLoading } from './manager-reducer';
import { IManager } from '../../interfaces/manager.interface';
import { showSnackbar } from '../snackbar/reducer';
import { errorMapper } from '../../utils/errorMapper';
import { ErrorMessages } from '../../constants/errors';
import { ResponseMessage } from '../../constants/response.messages';

interface IIntermediate {
    id: string,
    pageSize?: number | null,
    page?: number | null,
}


export const getManagers = (params?:any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse =
            await axiosInstance.get(MANAGERS_LIST, {params});
        dispatch(setManagers(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingManagers(false));
    }
};

export const getIntermediate = (params:IIntermediate) => async (dispatch: AppDispatch)=>{
    const {id,...payload} = params;
    try{
        const {data}: AxiosResponse =
            await axiosInstance.get(INTERMEDIATE_LIST+id, { params: payload});
        dispatch(setManagers(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    }
};

export const getManager = (params: any) => async (dispatch: AppDispatch)=>{
    try{
        const {data}: AxiosResponse =
          await axiosInstance.get(MANAGERS_LIST, {
              params
          });
        dispatch(setManager(data));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.GET_DATA,e), 'error'));
    } finally {
        dispatch(setLoadingManager(false));
    }
};

export const getManagerLog = (params: any) => async (dispatch: AppDispatch)=>{
    try{
        const {id,...query} = params;
        const {data}: AxiosResponse<any> =
          await axiosInstance.get(`${USER_LOGS}${id}`, {params:query});
        dispatch(setManagerLogs(data));

    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.LOGS_FAILED,e), 'error'));
    } finally {
        dispatch(setManagerLogsLoading(false));
    }
};


export const createManager = (formData: any) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination,role}}} = store.getState();
    try{
        const {data}: AxiosResponse =
            await axiosInstance.post(MANAGER_CREATE, {...formData,role});

        dispatch(setManager(data));
        dispatch(getManagers({
            pageSize:pagination.perPage,page: pagination.page+1,role
        },));
        dispatch(closeModal());
        dispatch(showSnackbar(ResponseMessage.SAVED_SUCCESS, 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.CREATE_DATA,e), 'error'));
    }
};

export const removeManager = (id: number) => async (dispatch: AppDispatch)=>{
    const {modal: {data: {pagination,role}}, managerList: {managers}} = store.getState();
    try{
        await axiosInstance.delete(`${MANAGER_REMOVE}/${id}`);
        if((managers.length-1)===0){
            dispatch(getManagers({
                pageSize:pagination.perPage,page: pagination.page
            }));
        } else {
            dispatch(getManagers({
                pageSize:pagination.perPage,page: pagination.page+1,role
            }));
        }
        dispatch(closeModal());
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.REMOVE_DATA,e), 'error'));
    }
};

export const updateManager = (formData: IManager) => async (dispatch: AppDispatch)=>{
    const {id, ...body} = formData;
    try{
        const {data}: AxiosResponse =
          await axiosInstance.patch(`${MANAGER_UPDATE}${id}`, body);
        dispatch(setManager(data));
        dispatch(closeModal());
        dispatch(updateManagerAtTable(data));
        dispatch(showSnackbar(ResponseMessage.UPDATED_SUCCESS, 'success'));
    }catch (e) {
        dispatch(showSnackbar(errorMapper(ErrorMessages.UPDATE_DATA,e), 'error'));
    }
};
