import { createSlice, Draft } from '@reduxjs/toolkit';
import { IInputTransaction } from '../../interfaces/input-transaction.interface';

export interface IInputTransactionState {
  data: IInputTransaction[],
  count: number;
  total: number;
  totalDirtyMoney: number;
  subscription: number;
  isLoading: boolean;
}

const initialState: IInputTransactionState = {
    data: [],
    count: 0,
    total: 0,
    totalDirtyMoney: 0,
    subscription: 0,
    isLoading: true
};

export const inputTransactionSlice = createSlice({
    name: 'inputTransaction',
    initialState,
    reducers:{
        setInputTransaction: (state: Draft<IInputTransactionState>, {payload}) => {
            state.data = payload.data;
            state.count = payload.count;
            state.total = payload.total;
            state.totalDirtyMoney = payload.totalDirtyMoney;
            state.subscription = payload?.subscription;
        },
        setLoadingInputTransaction: (state: Draft<IInputTransactionState>, {payload}) => {
            state.isLoading = payload;
        },
    }
});

export const {
    setInputTransaction,
    setLoadingInputTransaction
} = inputTransactionSlice.actions;

export default inputTransactionSlice.reducer;
