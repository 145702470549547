import {createSlice,Draft} from '@reduxjs/toolkit';

export interface ICurrency {
    buy: string;
    sell: string;
}

export interface ICurrencyState{
    data: ICurrency;
    isLoading: boolean;
    error: string | null;
}

const initialState = {
    data: {
        buy: '',
        sell: '',
    },
    isLoading: false,
    error: null,
};

export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrency: (state: Draft<ICurrencyState>,{payload})=>{
            state.data = payload;
        },
        setLoadingCurrency: (state: Draft<ICurrencyState>,{payload})=>{
            state.isLoading = payload;
        },
        setCurrencyError: (state: Draft<ICurrencyState>, {payload})=>{
            state.error = payload;
        }
    }
});

export const {
    setCurrency,
    setLoadingCurrency,
    setCurrencyError,
} = currencySlice.actions;

export default currencySlice.reducer;
