import React from 'react';
import {Autocomplete, Chip, TextField, Theme} from '@mui/material';
import { SxProps } from '@mui/system';

interface IAutocompleteFieldProps {
    label: string;
    sx?: SxProps<Theme>;
    selectedValue?: any;
    setSelectedValue?(state: any): void;
    fetchData?(): void;
    clearData?(): void;
    dataOptions: any[];
    displayValue1: string;
    displayValue2?: string;
    targetValue: string;
    multiple?: boolean;
    defaultValue?: any[];
    disabled?: boolean;
    value?: any;
    onChangeRender?: (v: any) => void;
    inputProps?: {
      error: boolean;
      helperText: string | null
    };
    isLoading?: boolean;
}

const MyChip = (props:any) => {
    return (
        <Chip
            sx={{
                borderRadius: 0,
                backgroundColor: 'transparent',
                boxSizing: 'border-box',
                fontSize: 'px',
                padding: 0,
                margin: '0px !important',
            }}
            {...props}
        />
    );
};

export default function AutocompleteField (
    {
        label,
        sx,
        selectedValue,
        setSelectedValue,
        targetValue,
        fetchData,
        dataOptions,
        displayValue1,
        displayValue2 = '',
        clearData,
        multiple = false,
        defaultValue = undefined,
        disabled = false,
        onChangeRender,
        value,
        inputProps,
        isLoading,
    }:IAutocompleteFieldProps
) {
    const [open, setOpen] = React.useState(false);
    const loading = open && dataOptions.length === 0 || open && isLoading;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (active && fetchData) {
            fetchData();
        }

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open && !selectedValue && clearData) {
            clearData();
        }
    }, [open, selectedValue]);


    const onChangeHandler = (value: any) => {
        if (onChangeRender) return onChangeRender(value);

        if (setSelectedValue)
            if(!multiple){
                const item: any = value;
                setSelectedValue(item ? item[targetValue]: null);
            } else {
                const item: any[] = value;
                setSelectedValue(item ? item.map(el=>el[targetValue]): null);
            }

    };

    return (
        <Autocomplete
            disabled={disabled}
            multiple={multiple}
            value={value}
            id="asynchronous-demo"
            size="small"
            sx={{width: 270, ...sx}}
            open={open}
            defaultValue={defaultValue}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, item) => {
                onChangeHandler(item);
            }}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                    <MyChip {...getTagProps({ index })} label={option[displayValue1]} key={index}/>
                ));
            }}
            isOptionEqualToValue={
                (option, value) => option[displayValue1] === value[displayValue1]
            }
            getOptionLabel={(option) => option ?
                `${option[displayValue1]} ${displayValue2 && option[displayValue2]}`:
                ''
            }
            options={dataOptions}
            loading={isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={label}
                    error={inputProps?.error}
                    helperText={inputProps?.helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )
            }
        />
    );
}
