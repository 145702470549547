import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {closeModal} from '../../../store/modal/reducer';
import {Box, Button, MenuItem, Modal, TextField} from '@mui/material';
import classes from '../modal.module.scss';
import {useForm} from 'react-hook-form';
import { formatDateOnlyDay } from '../../../utils/dateFormat';
import { paySalary, paySalaryForAll } from '../../../store/salary/thunk';
import {useSelector} from 'react-redux';
import { last5weeks } from '../../../store/weeks/weeks.selector';

interface IForm {
    salary: number;
    week: number;
}

export const PaySalaryModal = () => {
    const dispatch = useAppDispatch();
    const {data: modal} = useAppSelector(state => state.modal);
    const rows = useSelector(last5weeks);
    const onCloseModal = () => dispatch(closeModal());

    const {register, handleSubmit, formState: { errors }, setValue , watch, getValues} = useForm();

    useEffect(()=>{
        const selectedWeek = rows.find(el=>(el.from===modal.from && el.to===modal.to));
        if(selectedWeek){
            setValue('week',selectedWeek.id);
        }
    },[rows]);

    const onSubmit = (data:IForm) => {
        const currentWeek = rows.find(el=>(el.id===data.week));
        if(currentWeek){
            if(modal?.item?.id){
                dispatch(paySalary({
                    ...data,
                    managerId: modal?.item?.id,
                    exchangeRate: +modal.exchangeRate,
                    from: currentWeek.from,
                    to: currentWeek.to,
                }));
            } else {
                dispatch(paySalaryForAll({
                    ...data,
                    exchangeRate: +modal.exchangeRate,
                    from: currentWeek.from,
                    to: currentWeek.to,
                    pagination: modal?.pagination,
                }));
            }
        }
    };

    watch('week');
    return (
        <Modal
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{
                position:'fixed',
                overflow:'auto',
                height:'100%',
            }}
            open={true}
            className={classes.container}
        >
            <Box
                className={classes.wrapper}
                sx={{
                    position: 'inherit',
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ m: 1 }} >
                    <p>Выплатить зарплату {!modal?.item?.id ? 'ВСЕМ!!!' : modal?.item?.nickName}</p>
                </Box>
                <TextField
                    fullWidth={true}
                    margin="normal"
                    required
                    size="small"
                    label="Неделя"
                    select
                    error={!!errors.week}
                    defaultValue={''}
                    value={getValues('week') ? getValues('week') : ''}
                    helperText={errors.week ? errors.week.message : null}
                    id="week"
                    {...register('week',
                        {
                            required: {value: true, message: 'укажите неделю'},
                        })}
                >
                    {rows ? rows.map((row) => (
                        <MenuItem key={+row.id} value={row.id}>
                            {formatDateOnlyDay(row.from)}-{formatDateOnlyDay(row.to)}
                        </MenuItem>
                    )) : <span> </span>}
                </TextField>
                {Boolean(modal?.item?.id) && <TextField
                    margin="normal"
                    sx={{
                        mb: 5
                    }}
                    error={!!errors.salary}
                    helperText={errors.salary ? errors.salary.message : null}
                    required
                    fullWidth
                    type="number"
                    size='small'
                    label="Сумма"
                    id="salary"
                    {...register('salary',
                        {
                            setValueAs: v => v ? parseFloat(v) : v,
                            required: {value: true, message: 'введите сумму'},
                        })}
                />}
                <Box sx={{
                    display:'flex',
                    width: '100%',
                    justifyContent: 'space-around'
                }}>
                    <Button autoFocus onClick={onCloseModal}>
                        Отменить
                    </Button>
                    <Button
                        variant="outlined"
                        type={'submit'}
                    >Выплатить</Button>
                </Box>
            </Box>
        </Modal>
    );
};
